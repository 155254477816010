import React from 'react'
import { string } from 'prop-types'

const SalesDay = ({ color, ...rest }) => (
  <svg viewBox="0 0 20 17" fill="none" {...rest}>
    <path
      d="M6.12849 10.3123C5.42223 10.3123 4.75133 10.0883 4.18804 9.66462L4.02344 9.54086L5.28354 8.28076L5.40242 8.35026C5.8383 8.60387 6.41806 8.60387 6.85364 8.35026L6.97282 8.28076L8.23293 9.54086L8.06833 9.66462C7.50564 10.0883 6.83474 10.3123 6.12849 10.3123Z"
      fill={color}
    />
    <path
      d="M3.66992 9.18857L3.54617 9.02397C3.12248 8.46067 2.89844 7.78977 2.89844 7.08382C2.89844 6.37787 3.12248 5.70667 3.54617 5.14338C3.72936 4.90105 3.94487 4.68524 4.18719 4.50235H4.1875C4.62186 4.17498 5.13608 3.96252 5.6753 3.88723L5.87891 3.85889V5.63961L5.7451 5.67436C5.62683 5.70515 5.51161 5.753 5.40249 5.81671C5.17693 5.94595 4.99038 6.1325 4.86175 6.35684C4.73434 6.5757 4.66728 6.82687 4.66728 7.08352C4.66728 7.34017 4.73434 7.59134 4.86114 7.80898L4.93033 7.92816L3.66992 9.18857Z"
      fill={color}
    />
    <path
      d="M9.35316 6.92758C9.34707 6.79651 9.33304 6.66635 9.31018 6.53772C9.29098 6.41732 9.26355 6.29814 9.2291 6.18109C9.21843 6.14055 9.20533 6.10001 9.19222 6.06068C9.16235 5.97015 9.1279 5.88176 9.08858 5.79367C9.03615 5.67205 8.97519 5.55408 8.906 5.43947C8.84625 5.33797 8.78072 5.23921 8.70939 5.1438C8.68684 5.11393 8.66276 5.08284 8.63898 5.05449C8.53748 4.92921 8.42683 4.81125 8.30856 4.70274C8.23236 4.63232 8.15128 4.56557 8.06776 4.50247C7.6337 4.17571 7.11979 3.96234 6.58087 3.88705L6.37695 3.85962V4.74206V5.09869V5.48977V5.64004L6.51046 5.67448C6.62842 5.70558 6.74425 5.75313 6.85399 5.81744C6.86466 5.82354 6.87532 5.82933 6.88508 5.83665H6.88874C7.05334 5.93906 7.19172 6.06556 7.30359 6.21949C7.33712 6.26369 7.36669 6.31002 7.39412 6.35788C7.43466 6.42707 7.4691 6.4987 7.49654 6.57369C7.54317 6.69775 7.57152 6.82882 7.58249 6.96233C7.58737 7.00287 7.58859 7.04341 7.58859 7.08395C7.58859 7.16381 7.58249 7.24367 7.56939 7.32109C7.54683 7.45826 7.50507 7.59055 7.44533 7.71339C7.42978 7.7457 7.4118 7.7777 7.39412 7.8088L7.32493 7.92798L7.46788 8.07094L8.58533 9.18839L8.70939 9.02379C8.93587 8.72202 9.10535 8.38947 9.21386 8.03649C9.25074 7.91853 9.28062 7.79935 9.30195 7.67742C9.32695 7.54848 9.34249 7.41741 9.3495 7.28512C9.35438 7.21837 9.35651 7.15162 9.35651 7.08364C9.35682 7.03121 9.3556 6.98001 9.35316 6.92758Z"
      fill={color}
    />
    <path d="M12.3349 5.84985H10.6094V8.93336H12.3349V5.84985Z" fill={color} />
    <path d="M12.3349 9.53101H10.6094V10.3129H12.3349V9.53101Z" fill={color} />
    <path d="M14.8232 4.78784H13.0977V8.93332H14.8232V4.78784Z" fill={color} />
    <path d="M14.8232 9.53101H13.0977V10.3129H14.8232V9.53101Z" fill={color} />
    <path d="M17.3115 3.85889H15.5859V8.93374H17.3115V3.85889Z" fill={color} />
    <path d="M17.3115 9.53101H15.5859V10.3129H17.3115V9.53101Z" fill={color} />
    <path
      d="M18.9364 0.678711H1.25381C0.897176 0.678711 0.605469 0.970418 0.605469 1.32705V13.7702C0.605469 14.1283 0.895652 14.4185 1.25381 14.4185H7.07272V15.839H6.28538C5.84797 15.839 5.49286 16.1889 5.49286 16.6263H14.8619C14.8412 16.1889 14.4913 15.839 14.0542 15.839H13.1586V14.4185H18.9571C19.3138 14.4185 19.6055 14.1268 19.6055 13.7702V1.34747C19.6055 0.978039 19.3058 0.678711 18.9364 0.678711ZM10.1053 13.8162C9.7246 13.8162 9.41583 13.5074 9.41583 13.1319C9.41583 12.7512 9.7246 12.4424 10.1053 12.4424C10.486 12.4424 10.7948 12.7512 10.7948 13.1319C10.7948 13.5077 10.486 13.8162 10.1053 13.8162ZM18.2231 11.6453H1.9875V2.07263H18.2231V11.6453Z"
      fill={color}
    />
  </svg>
)

SalesDay.propTypes = {
  color: string
}

export default SalesDay
