import React from 'react'
import { string } from 'prop-types'

const MetabolicDisorders = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 20" fill="none" {...rest}>
    <path
      d="M11.0002 4.75122C11.0868 5.16515 11.1637 5.53242 11.2394 5.89415C10.8341 6.00435 10.4434 6.0981 10.0606 6.21714C9.01195 6.54323 8.57422 7.08799 8.60985 8.1913C8.64573 9.30165 8.74627 10.412 8.86693 11.5171C9.03194 13.0282 9.16957 14.5356 8.9359 16.051C8.76791 17.1401 8.37936 18.1351 7.66934 18.99C7.4477 19.2568 7.21825 19.5174 6.98619 19.7753C6.74569 20.0426 6.39687 20.0731 6.14583 19.8577C5.88964 19.6379 5.86844 19.2987 6.10203 19.0161C6.25104 18.8358 6.40866 18.6625 6.56683 18.4901C7.43262 17.5468 7.77817 16.4009 7.87317 15.1554C7.97392 13.835 7.78988 12.5318 7.65949 11.2233C7.55564 10.1815 7.44834 9.13318 7.45659 8.08863C7.46893 6.52715 8.15908 5.64254 9.63381 5.13346C10.0721 4.98207 10.528 4.88169 11.0002 4.75122Z"
      fill={color}
    />
    <path
      d="M9.48551 11.5137C9.43419 11.12 9.38569 10.7473 9.33594 10.3656C9.67639 10.2594 10.0089 10.1664 10.3335 10.0511C10.5909 9.95971 10.8477 9.85828 11.0892 9.73163C12.0155 9.24571 12.4213 8.50722 12.2488 7.47871C12.0998 6.59097 11.8773 5.71505 11.6721 4.83745C11.4439 3.86127 11.2307 2.88516 11.3609 1.87295C11.4293 1.3405 11.5828 0.833542 11.8502 0.365198C12.048 0.0189175 12.377 -0.0922463 12.682 0.079768C12.9763 0.24579 13.058 0.578597 12.8731 0.91973C12.4167 1.76182 12.4101 2.65632 12.5969 3.55721C12.7784 4.43213 13.0167 5.29513 13.2181 6.1661C13.3875 6.89836 13.514 7.6348 13.4016 8.39348C13.278 9.22741 12.8777 9.88993 12.2092 10.3952C11.4048 11.0031 10.4603 11.266 9.48551 11.5137Z"
      fill={color}
    />
    <path
      d="M6.98713 10.7373C7.05313 11.1329 7.11362 11.4953 7.17768 11.8791C6.70363 11.9291 6.25721 11.987 5.80901 12.0216C4.27496 12.1399 2.99513 12.7898 1.91993 13.8687C1.62963 14.1599 1.3514 14.4633 1.0716 14.7649C0.792084 15.0661 0.467481 15.1086 0.200993 14.872C-0.0637243 14.6371 -0.0693951 14.3029 0.19846 13.9952C1.11094 12.9469 2.1102 12.0059 3.40129 11.4295C4.19151 11.0767 5.03006 10.9269 5.88277 10.8381C6.24208 10.8007 6.60236 10.7721 6.98713 10.7373Z"
      fill={color}
    />
    <path
      d="M6.7776 17.4828C6.56424 17.7479 6.36343 17.9974 6.14464 18.2692C4.76036 16.983 3.39334 15.7127 2.00781 14.4253C2.25813 14.1937 2.4984 13.9714 2.7496 13.739C4.09945 14.9936 5.43137 16.2316 6.7776 17.4828Z"
      fill={color}
    />
    <path
      d="M13.6598 5.44169C13.5641 5.06058 13.4726 4.69624 13.3945 4.38535C13.8886 4.08315 14.3906 3.84506 14.8059 3.50208C15.1975 3.17872 15.5037 2.74987 15.8357 2.35746C15.9934 2.17101 16.1691 2.05405 16.4186 2.09105C16.655 2.12608 16.8341 2.25691 16.8844 2.48942C16.9144 2.62813 16.9156 2.81402 16.846 2.92711C16.0959 4.14557 15.0457 4.99149 13.6598 5.44169Z"
      fill={color}
    />
    <path
      d="M7.17959 16.1039C6.06969 15.0762 4.97667 14.0642 3.83398 13.0062C4.22189 12.8764 4.55973 12.7565 4.90303 12.6552C4.96022 12.6383 5.05759 12.6843 5.10875 12.7313C5.84499 13.4079 6.577 14.089 7.30789 14.7714C7.35233 14.8129 7.41089 14.8747 7.40892 14.9249C7.39332 15.3166 7.40699 15.7161 7.17959 16.1039Z"
      fill={color}
    />
    <path
      d="M11.7044 8.31909C10.9928 7.87472 10.2812 7.43034 9.5696 6.98593C9.56658 6.96132 9.56356 6.93674 9.56055 6.91213C9.87256 6.79738 10.1827 6.67689 10.4984 6.57345C10.5516 6.55603 10.6357 6.60104 10.6939 6.63635C11.0029 6.82389 11.3147 7.00845 11.611 7.21485C11.6955 7.27373 11.7726 7.39861 11.7817 7.49968C11.8054 7.76403 11.7897 8.03193 11.7897 8.29842C11.7613 8.30533 11.7329 8.31221 11.7044 8.31909Z"
      fill={color}
    />
    <path
      d="M11.22 9.14872C10.8847 9.30308 10.6067 9.43962 10.3204 9.55565C10.2629 9.57893 10.1642 9.54036 10.101 9.50272C9.80311 9.32532 9.50694 9.14421 9.21978 8.95028C9.1525 8.90483 9.08634 8.80642 9.08175 8.72872C9.06526 8.44803 9.07528 8.16578 9.07528 7.81592C9.80158 8.26729 10.4787 8.6881 11.22 9.14872Z"
      fill={color}
    />
    <path
      d="M13.0703 1.62748C13.1629 1.4216 13.2494 1.22912 13.3434 1.02002C14.0468 1.39723 14.7185 1.75746 15.4266 2.13717C15.3027 2.30037 15.1949 2.45469 15.0715 2.59525C15.044 2.6265 14.9487 2.63326 14.906 2.61074C14.3022 2.29265 13.7021 1.96749 13.0703 1.62748Z"
      fill={color}
    />
    <path
      d="M12.9914 2.37793C13.486 2.64237 13.9712 2.90169 14.5129 3.19127C14.2031 3.30448 14.062 3.68406 13.7043 3.5133C12.9519 3.15411 12.8837 3.03622 12.9914 2.37793Z"
      fill={color}
    />
  </svg>
)

MetabolicDisorders.propTypes = {
  color: string
}

export default MetabolicDisorders
