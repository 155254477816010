import React from 'react'
import { string } from 'prop-types'

const Sales = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 14" fill="none" {...rest}>
    <path
      d="M1.30919 12.6402V0H0.216797V1.48538V2.14484V3.6066H0.684422V4.26606H0.216797V5.72812H0.684422V6.38728H0.216797V7.84934H0.684422V8.5088H0.216797V9.97056H0.684422V10.63H0.216797V13.7326H3.09223V13.2311H3.75168V13.7326H5.84434V13.2311H6.50379V13.7326H8.59645V13.2311H9.25561V13.7326H11.3483V13.2311H12.0077V13.7326H14.1004V13.2311H14.7595V13.7326H17V12.6402H1.30919Z"
      fill={color}
    />
    <path
      d="M2.2793 5.12029L4.17225 3.80837L7.03776 4.9579L10.1794 2.23961L12.8612 3.83519L16.9999 0.651609L16.6095 0.144043L12.8201 3.05912L10.1196 1.45217L6.91882 4.22031L4.09091 3.08594L2.2793 4.3413V5.12029Z"
      fill={color}
    />
    <path
      d="M2.2793 8.81873L4.12473 7.31177L7.27303 7.67007L10.4213 5.87245L13.1498 6.72257L16.9999 4.21332V2.42065L12.9676 4.9372L10.2651 3.61275L7.16633 6.35261L4.01977 5.21416L2.2793 6.54095V8.81873Z"
      fill={color}
    />
    <path
      d="M2.2793 11.7976H16.9999V6.80151L13.3866 8.4726L10.6231 7.62132L7.47536 9.4338L4.28886 9.08279L2.2793 11.5037V11.7976Z"
      fill={color}
    />
  </svg>
)

Sales.propTypes = {
  color: string
}

export default Sales
