import React, { useState } from 'react'
import { string, func, node, object } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import classnames from 'classnames'

import Label from '../Label'
import Button from '../Button'

import { black, white } from '../colors'

const StyledSearchBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d6dbf0;
  padding: 8px 8px 8px 30px;
  background-color: ${white};
  border-radius: 6px;

  :hover,
  &.active {
    box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);
  }
`

const StyledInputWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`

const StyledLabel = styled(Label)`
  font-size: 8.9033px;
  line-height: 15px;
  line-height: 1.4;
  opacity: 0.2;

  ${media.greaterThan('medium')`
    font-size: 14px;
  `}
`

const StyledInput = styled.input`
  font-size: 13.3549px;
  line-height: 15px;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  color: ${black};

  ${media.greaterThan('medium')`
    font-size: 21px;
  `}
    ::placeholder {
    color: #092c4c;
    opacity: 0.5;
    font-family: Poppins;
  }
`

const StyledIcon = styled.div`
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
`

const StyledButtonSearch = styled(Button)`
  width: 77.69px;
  height: 47.06px;
  flex-shrink: 0;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 10.1752px;
  line-height: 15px;
  font-weight: bold;

  ${media.greaterThan('medium')`
    width: 175px;
    height: 74px;
    font-size: 16px;
    line-height: 23px;
  `}
`

const SearchBar = ({
  label = 'Type your keyword',
  className,
  onSearch,
  iconButton,
  icon,
  styleButton
}) => {
  const [elementActive, setElementActive] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const handleValue = value => {
    setSearchValue(value)
    onSearch(value)
  }

  return (
    <StyledSearchBar
      className={classnames(className, {
        active: elementActive
      })}
    >
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledInputWrapper>
        <StyledLabel>{label}</StyledLabel>
        <StyledInput
          placeholder="Search Calculators"
          onChange={({ target: { value } }) => {
            handleValue(value)
          }}
          onFocus={() => setElementActive(true)}
          onBlur={() => setElementActive(false)}
          onKeyDown={({ key, target: { value } }) => {
            if (key === 'Enter') {
              handleValue(value)
            }
          }}
        />
      </StyledInputWrapper>
      <StyledButtonSearch
        style={styleButton}
        label={iconButton ? '' : 'SEARCH'}
        icon={iconButton}
        onClick={() => onSearch(searchValue)}
      />
    </StyledSearchBar>
  )
}

SearchBar.propTypes = {
  label: string,
  onSearch: func,
  className: string,
  iconButton: node,
  icon: node,
  styleButton: object
}

export default SearchBar
