import React from 'react'
import { string } from 'prop-types'

const BusinessPlanning = ({ color, ...rest }) => (
  <svg viewBox="0 0 16 16" fill="none" {...rest}>
    <path
      d="M0.588634 6.00621C0.773703 6.19128 1.02607 6.29222 1.26161 6.29222C1.49715 6.29222 1.74952 6.19128 1.93459 6.00621L3.33101 4.60979L4.72744 6.00621C4.9125 6.19128 5.16487 6.29222 5.40041 6.29222C5.63595 6.29222 5.88832 6.19128 6.07339 6.00621C6.44352 5.63607 6.44352 5.0304 6.07339 4.64343L4.67696 3.24701L6.07339 1.85058C6.44352 1.48045 6.44352 0.874769 6.07339 0.487808C5.70325 0.117671 5.09757 0.117671 4.71061 0.487808L3.31419 1.88423L1.95141 0.504632C1.58127 0.134495 0.975595 0.134495 0.588634 0.504632C0.201673 0.874769 0.218497 1.48045 0.588634 1.86741L1.98506 3.26383L0.588634 4.66026C0.218497 5.0304 0.218497 5.63607 0.588634 6.00621Z"
      fill={color}
    />
    <path
      d="M15.4288 9.9768C15.0587 9.60666 14.453 9.60666 14.066 9.9768L12.6696 11.3564L11.2732 9.95998C10.9031 9.58984 10.2974 9.58984 9.91042 9.95998C9.54028 10.3301 9.54028 10.9358 9.91042 11.3228L11.3068 12.7192L9.91042 14.1156C9.54028 14.4857 9.54028 15.0914 9.91042 15.4784C10.0955 15.6634 10.3478 15.7644 10.5834 15.7644C10.8358 15.7644 11.0713 15.6634 11.2564 15.4784L12.6528 14.082L14.0492 15.4784C14.2343 15.6634 14.4867 15.7644 14.7222 15.7644C14.9577 15.7644 15.2101 15.6634 15.3952 15.4784C15.7653 15.1082 15.7653 14.5026 15.3952 14.1156L13.9987 12.7192L15.3952 11.3228C15.799 10.9526 15.799 10.3469 15.4288 9.9768Z"
      fill={color}
    />
    <path
      d="M15.041 0H10.6498C10.1115 0 9.69085 0.437434 9.69085 0.958991C9.69085 1.48055 10.1283 1.91798 10.6498 1.91798H12.7192L4.98002 9.6572C4.50894 9.40484 3.97056 9.23659 3.3817 9.23659C1.5142 9.23659 0 10.7508 0 12.6183C0 14.4858 1.5142 16 3.3817 16C5.24921 16 6.76341 14.4858 6.76341 12.6183C6.76341 12.0294 6.59516 11.4911 6.3428 11.02L14.082 3.28076V5.36698C14.082 5.88854 14.5195 6.32597 15.041 6.32597C15.5794 6.32597 16 5.88854 16 5.36698V0.958991C16 0.437434 15.5626 0 15.041 0ZM3.39853 14.0652C2.59096 14.0652 1.93481 13.409 1.93481 12.6015C1.93481 11.7939 2.59096 11.1377 3.39853 11.1377C4.2061 11.1377 4.86225 11.7939 4.86225 12.6015C4.84543 13.409 4.18927 14.0652 3.39853 14.0652Z"
      fill={color}
    />
  </svg>
)

BusinessPlanning.propTypes = {
  color: string
}

export default BusinessPlanning
