import React from 'react'
import { string } from 'prop-types'

const MicroMacroEconomicsDays = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 17" fill="none" {...rest}>
    <path
      d="M16.8342 10.5791C16.4834 9.25561 15.6274 8.19698 14.5302 7.56002C13.4331 6.92241 12.0894 6.70318 10.766 7.05429C10.073 7.23794 9.45278 7.56033 8.9275 7.98317V8.8997C9.45601 8.34138 10.1404 7.91864 10.939 7.70686C12.0845 7.40337 13.2411 7.59247 14.1911 8.14387C15.1405 8.69578 15.8777 9.60685 16.1816 10.7521C16.4851 11.8976 16.2961 13.0543 15.7447 14.0041C15.1927 14.9537 14.2816 15.6909 13.1363 15.9947C11.9909 16.2982 10.8343 16.1092 9.88434 15.5577C9.38599 15.2681 8.94623 14.8793 8.59684 14.4098H7.78516C8.22193 15.1362 8.83257 15.7279 9.54528 16.1416C10.6423 16.7792 11.9859 16.9983 13.3094 16.6472C14.6328 16.2966 15.6915 15.4406 16.3285 14.3432C16.9661 13.2463 17.1852 11.9025 16.8342 10.5791Z"
      fill={color}
    />
    <path d="M8.58384 4.15039H6.17578V14.0663H8.58384V4.15039Z" fill={color} />
    <path
      d="M16.6871 11.0659L16.3661 13.494L12.078 11.8744L12.0762 7.18921L15.2582 8.27505L16.6871 11.0659Z"
      fill={color}
    />
    <path d="M2.40806 9.62256H0V14.0664H2.40806V9.62256Z" fill={color} />
    <path d="M5.49399 7.99292H3.08594V14.0664H5.49399V7.99292Z" fill={color} />
    <path
      d="M1.03906 7.17026L3.09759 2.93899L4.21832 4.33415L7.35175 1.56668L11.9947 3.73949L16.0887 0.274414"
      stroke={color}
      strokeWidth="0.343077"
    />
    <path d="M16.3646 1.05211L15.3125 0H16.3646V1.05211Z" fill={color} />
    <path
      d="M1.56579 7.18432C1.56579 7.46702 1.33664 7.69616 1.05395 7.69616H1.02551C0.742813 7.69616 0.513672 7.46702 0.513672 7.18432V7.15588C0.513672 6.87318 0.742813 6.64404 1.02551 6.64404H1.05395C1.33664 6.64404 1.56579 6.87318 1.56579 7.15588V7.18432Z"
      fill={color}
    />
    <path
      d="M3.62438 2.95312C3.62438 3.23581 3.39524 3.46496 3.11254 3.46496H3.0841C2.80141 3.46496 2.57227 3.23581 2.57227 2.95312V2.92468C2.57227 2.64198 2.80141 2.41284 3.0841 2.41284H3.11254C3.39524 2.41284 3.62438 2.64198 3.62438 2.92468V2.95312Z"
      fill={color}
    />
    <path
      d="M4.67711 4.27954C4.67711 4.56223 4.44797 4.79137 4.16528 4.79137H4.13684C3.85414 4.79137 3.625 4.56223 3.625 4.27954V4.25109C3.625 3.9684 3.85414 3.73926 4.13684 3.73926H4.16528C4.44797 3.73926 4.67711 3.9684 4.67711 4.25109V4.27954Z"
      fill={color}
    />
    <path
      d="M7.88024 1.6499C7.88024 1.93259 7.6511 2.16173 7.3684 2.16173H7.33996C7.05727 2.16173 6.82812 1.93259 6.82812 1.6499V1.62146C6.82812 1.33876 7.05727 1.10962 7.33996 1.10962H7.3684C7.6511 1.10962 7.88024 1.33876 7.88024 1.62146V1.6499Z"
      fill={color}
    />
    <path
      d="M12.5228 3.75341C12.5228 4.03611 12.2936 4.26525 12.011 4.26525H11.9825C11.6999 4.26525 11.4707 4.03611 11.4707 3.75341V3.72497C11.4707 3.44228 11.6999 3.21313 11.9825 3.21313H12.011C12.2936 3.21313 12.5228 3.44228 12.5228 3.72497V3.75341Z"
      fill={color}
    />
    <path d="M8.21669 14.4099H0V14.7526H8.21669V14.4099Z" fill={color} />
  </svg>
)

MicroMacroEconomicsDays.propTypes = {
  color: string
}

export default MicroMacroEconomicsDays
