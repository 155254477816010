import React from 'react'
import { string } from 'prop-types'

const Investment = ({ color, ...rest }) => (
  <svg viewBox="0 0 15 24" fill="none" {...rest}>
    <path
      d="M9.78065 10.182C8.86666 10.7098 8.14979 11.4743 7.63812 12.4003V10.0559C10.0811 9.69987 11.9573 7.59676 11.9573 5.05615C11.9573 2.26282 9.69463 0 6.90314 0C4.11312 0 1.84874 2.26282 1.84874 5.05624C1.84874 7.59658 3.72597 9.69959 6.1678 10.0559V13.6872C5.76842 12.9247 5.22624 12.2227 4.54291 11.6222C3.23488 10.4729 1.62507 9.88393 0 9.83284C0.259879 11.4375 1.05118 12.9579 2.35912 14.1073C3.4694 15.083 4.79746 15.6556 6.1678 15.8382V16.9087H7.63812V14.586C9.16991 14.9178 10.6861 14.7526 11.9861 14.0019C13.4941 13.1314 14.4645 11.616 14.8613 9.79461C13.0857 9.22799 11.2886 9.31143 9.78065 10.182ZM7.55431 8.1066C7.43843 8.13453 7.37447 8.24306 7.37447 8.36289V8.63858C7.37447 8.77816 7.23534 8.91399 7.09787 8.91399L6.63913 8.91426C6.50064 8.91426 6.36363 8.77816 6.36363 8.63858V8.45479C6.36363 8.32338 6.24958 8.2233 6.12038 8.21108C5.63343 8.16238 5.1723 8.0363 4.83633 7.88743C4.72266 7.8367 4.66265 7.71274 4.69362 7.59061L4.86629 6.9093C4.88485 6.83394 4.93135 6.77715 4.99963 6.74297C5.06883 6.70989 5.14115 6.70878 5.21163 6.73984C5.59925 6.91444 6.08215 7.05109 6.60412 7.05109C7.18507 7.05109 7.57388 6.81841 7.57388 6.42253C7.57397 6.03777 7.25142 5.79498 6.49972 5.54154C5.42869 5.17662 4.6916 4.66899 4.6916 3.6859C4.6916 2.83918 5.22817 2.17046 6.18241 1.92428C6.29618 1.89542 6.36363 1.77339 6.36363 1.65457V1.47078C6.36363 1.33027 6.50064 1.19528 6.63913 1.19528L7.09842 1.19518C7.2369 1.19518 7.37447 1.33027 7.37447 1.47078V1.56267C7.37447 1.69491 7.47978 1.81373 7.61119 1.82402C8.034 1.85931 8.35967 1.95028 8.6315 2.05661C8.75151 2.10421 8.81658 2.2336 8.78349 2.35958L8.61496 3.00055C8.59529 3.07407 8.55082 3.12976 8.48364 3.16293C8.41637 3.19711 8.34617 3.19914 8.27688 3.1701C8.00597 3.05743 7.61726 2.93659 7.09823 2.93659C6.44716 2.93659 6.23819 3.2301 6.23819 3.51434C6.23819 3.839 6.59171 4.06129 7.45295 4.37557C8.64501 4.80151 9.12047 5.35866 9.12047 6.28083C9.12047 7.13058 8.58077 7.86363 7.55431 8.1066Z"
      fill={color}
    />
    <path
      d="M12.4301 19.1143L12.1758 19.8263L11.6358 20.3088L10.8547 23.5251H2.95195L2.17084 20.3088L1.63087 19.8263L1.3765 19.1143H1.11387C0.80933 19.1143 0.5625 18.8675 0.5625 18.5629C0.5625 18.2584 0.80933 18.0115 1.11387 18.0115H12.6927C12.9973 18.0115 13.2441 18.2584 13.2441 18.5629C13.2441 18.8675 12.9973 19.1143 12.6927 19.1143H12.4301Z"
      fill={color}
    />
  </svg>
)

Investment.propTypes = {
  color: string
}

export default Investment
