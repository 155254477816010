import React from 'react'
import { node, string, number, object, func } from 'prop-types'
import styled from 'styled-components'

import { marineBlue, solitude } from '../colors'

const StyledSeparator = styled.div`
  font-family: 'Poppins';
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 29px;
  color: ${marineBlue};
  min-height: 52px;
  padding: 10px 20px;
  flex: 1;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor || solitude};
`

const Separator = ({ children, fontSize = 16, className, style, backgroundColor, onClick }) => (
  <StyledSeparator
    fontSize={fontSize}
    backgroundColor={backgroundColor}
    className={className}
    style={style}
    onClick={onClick}
  >
    {children}
  </StyledSeparator>
)

Separator.propTypes = {
  children: node,
  fontSize: number,
  backgroundColor: string,
  className: string,
  style: object,
  onClick: func
}

export default Separator
