import React from 'react'
import { string } from 'prop-types'

const OnlineClock = ({ color, ...rest }) => (
  <svg viewBox="0 0 20 21" fill="none" {...rest}>
    <path
      d="M10 20.5C4.5 20.5 0 16 0 10.5C0 5 4.5 0.5 10 0.5C15.5 0.5 20 5 20 10.5C20 16 15.5 20.5 10 20.5ZM10 3C5.875 3 2.5 6.375 2.5 10.5C2.5 14.625 5.875 18 10 18C14.125 18 17.5 14.625 17.5 10.5C17.5 6.375 14.125 3 10 3ZM13.375 13.875C13.875 13.375 13.875 12.625 13.375 12.125L11.25 10V5.5C11.25 4.75 10.75 4.25 10 4.25C9.25 4.25 8.75 4.75 8.75 5.5V10.5C8.75 10.875 8.875 11.125 9.125 11.375L11.625 13.875C11.875 14.125 12.125 14.25 12.5 14.25C12.875 14.25 13.125 14.125 13.375 13.875Z"
      fill={color}
    />
  </svg>
)

OnlineClock.propTypes = {
  color: string
}

export default OnlineClock
