import React from 'react'
import { string } from 'prop-types'

const Sequences = ({ color, ...rest }) => (
  <svg viewBox="0 0 19 10" fill="none" {...rest}>
    <path
      d="M2.07811 0.593475H6.77602C6.71456 0.420121 6.60097 0.270026 6.45084 0.163773C6.30071 0.0575206 6.12139 0.000314237 5.93746 0H0.890619C0.654488 0.000260894 0.428103 0.0941813 0.261135 0.261154C0.0941673 0.428127 0.00025381 0.654515 0 0.890646L0 5.93764C0.000311625 6.12162 0.0575466 6.30099 0.163852 6.45115C0.270157 6.60131 0.420323 6.7149 0.593746 6.77633V0.890646C0.593834 0.811937 0.62514 0.736476 0.680795 0.680819C0.73645 0.625163 0.81191 0.593854 0.890619 0.593764H2.0724C2.07433 0.593755 2.07618 0.593475 2.07811 0.593475Z"
      fill={color}
    />
    <path
      d="M7.12496 1.78102H7.96363C7.90221 1.6076 7.78863 1.45743 7.63847 1.35112C7.48832 1.24481 7.30894 1.18757 7.12496 1.18726H2.07812C1.84199 1.18752 1.6156 1.28144 1.44864 1.44841C1.28167 1.61538 1.18775 1.84177 1.1875 2.0779V7.12491C1.18781 7.30889 1.24505 7.48826 1.35135 7.63842C1.45766 7.78858 1.60782 7.90216 1.78125 7.96359V2.0779C1.78133 1.99919 1.81264 1.92373 1.8683 1.86807C1.92395 1.81242 1.99941 1.78111 2.07812 1.78102H7.12496Z"
      fill={color}
    />
    <path
      d="M2.375 3.2654V8.31241C2.37526 8.54854 2.46917 8.77493 2.63614 8.9419C2.8031 9.10887 3.02949 9.20279 3.26562 9.20306H8.31246C8.54859 9.20279 8.77498 9.10887 8.94195 8.9419C9.10891 8.77493 9.20283 8.54854 9.20308 8.31241V3.2654C9.20283 3.02927 9.10892 2.80288 8.94195 2.63591C8.77498 2.46894 8.54859 2.37502 8.31246 2.37476H3.26562C3.02949 2.37502 2.8031 2.46894 2.63614 2.63591C2.46917 2.80288 2.37525 3.02927 2.375 3.2654ZM3.56228 3.85931C3.56228 3.78058 3.59356 3.70507 3.64923 3.64939C3.7049 3.59372 3.78041 3.56244 3.85915 3.56243H5.04664C5.12538 3.56243 5.20089 3.59371 5.25657 3.64938C5.31225 3.70506 5.34352 3.78057 5.34352 3.85931C5.34352 3.93805 5.31225 4.01356 5.25657 4.06924C5.20089 4.12492 5.12538 4.15619 5.04664 4.15619H4.15602V5.04684C4.15602 5.12558 4.12474 5.20109 4.06907 5.25676C4.0134 5.31244 3.93788 5.34371 3.85915 5.34371C3.78041 5.34371 3.7049 5.31244 3.64923 5.25676C3.59355 5.20109 3.56228 5.12558 3.56228 5.04684V3.85931Z"
      fill={color}
    />
    <path
      d="M18.11 0H13.0632C12.8792 0.000314237 12.6999 0.0575206 12.5498 0.163773C12.3997 0.270026 12.2861 0.420121 12.2246 0.593475H16.9225C16.9244 0.593475 16.9263 0.593755 16.9282 0.593772H18.11C18.1887 0.593862 18.2642 0.62517 18.3198 0.680827C18.3755 0.736484 18.4068 0.811944 18.4069 0.890653V6.77633C18.5803 6.71491 18.7305 6.60132 18.8368 6.45116C18.9431 6.301 19.0003 6.12162 19.0006 5.93764V0.890652C19.0004 0.65452 18.9065 0.42813 18.7395 0.261156C18.5725 0.0941817 18.3461 0.000260897 18.11 0Z"
      fill={color}
    />
    <path
      d="M16.9226 1.18726H11.8758C11.6918 1.18757 11.5124 1.24481 11.3623 1.35112C11.2121 1.45743 11.0985 1.6076 11.0371 1.78102H16.9226C17.0013 1.78111 17.0768 1.81242 17.1324 1.86807C17.1881 1.92373 17.2194 1.99919 17.2195 2.0779V7.96359C17.3929 7.90216 17.5431 7.78858 17.6494 7.63842C17.7557 7.48826 17.8129 7.30889 17.8132 7.12491V2.0779C17.813 1.84177 17.7191 1.61538 17.5521 1.44841C17.3851 1.28144 17.1588 1.18752 16.9226 1.18726Z"
      fill={color}
    />
    <path
      d="M15.7343 2.37476H10.6875C10.4514 2.37502 10.225 2.46894 10.058 2.63591C9.89104 2.80288 9.79713 3.02927 9.79688 3.2654V8.31241C9.79713 8.54854 9.89104 8.77493 10.058 8.9419C10.225 9.10887 10.4514 9.20279 10.6875 9.20306H15.7343C15.9705 9.20279 16.1969 9.10887 16.3638 8.9419C16.5308 8.77493 16.6247 8.54854 16.625 8.31241V3.2654C16.6247 3.02927 16.5308 2.80288 16.3638 2.63591C16.1969 2.46894 15.9705 2.37502 15.7343 2.37476ZM15.4377 5.04684C15.4377 5.12558 15.4064 5.20109 15.3507 5.25676C15.2951 5.31244 15.2195 5.34371 15.1408 5.34371C15.0621 5.34371 14.9866 5.31244 14.9309 5.25676C14.8752 5.20109 14.8439 5.12558 14.8439 5.04684V4.15619H13.9533C13.8746 4.15619 13.7991 4.12492 13.7434 4.06924C13.6877 4.01356 13.6564 3.93805 13.6564 3.85931C13.6564 3.78057 13.6877 3.70506 13.7434 3.64938C13.7991 3.59371 13.8746 3.56243 13.9533 3.56243H15.1408C15.2195 3.56244 15.2951 3.59372 15.3507 3.64939C15.4064 3.70507 15.4377 3.78058 15.4377 3.85931V5.04684Z"
      fill={color}
    />
  </svg>
)

Sequences.propTypes = {
  color: string
}

export default Sequences
