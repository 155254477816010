import React from 'react'
import { string } from 'prop-types'

const GeneralHealth = ({ color, ...rest }) => (
  <svg viewBox="0 0 20 20" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.949219 17.4633C0.949219 18.3404 1.6597 19.0508 2.53671 19.0508H18.4125C19.2896 19.0508 20 18.3404 20 17.4633V5.55643C20 4.67933 19.2896 3.96885 18.4125 3.96885H15.2373V1.58749C15.2373 0.710438 14.5269 0 13.6497 0H7.29952C6.42239 0 5.71195 0.710482 5.71195 1.58749V3.96885H2.53675C1.6597 3.96885 0.949262 4.67933 0.949262 5.55643L0.949219 17.4633ZM13.2529 3.96881H7.69635V2.8577C7.69635 2.37354 8.08536 1.9844 8.56964 1.9844H12.3796C12.8638 1.9844 13.2529 2.37359 13.2529 2.8577L13.2529 3.96881ZM6.58511 9.52537H8.49018V7.62038C8.49018 7.13623 8.87906 6.74709 9.36339 6.74709H11.5859C12.0699 6.74709 12.459 7.13627 12.459 7.62038V9.52537H14.364C14.8482 9.52537 15.2373 9.91446 15.2373 10.3987V12.6211C15.2373 13.1052 14.8482 13.4942 14.364 13.4942H12.459V15.3994C12.459 15.8834 12.0699 16.2725 11.5859 16.2725H9.36339C8.8791 16.2725 8.49018 15.8834 8.49018 15.3994V13.4942H6.58511C6.10082 13.4942 5.7119 13.1052 5.7119 12.6211V10.3987C5.71195 9.91446 6.10082 9.52537 6.58511 9.52537Z"
      fill={color}
    />
  </svg>
)

GeneralHealth.propTypes = {
  color: string
}

export default GeneralHealth
