import React from 'react'
import { string } from 'prop-types'

const Reload = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 17" fill="none" {...rest}>
    <path
      d="M8.5 0C3.80457 0 0 3.80457 0 8.5C0 13.1954 3.80457 17 8.5 17C13.1954 17 17 13.1954 17 8.5C17 3.80457 13.1954 0 8.5 0ZM8.5 13.7349C5.61082 13.7349 3.26923 11.3933 3.26923 8.5C3.26923 5.60673 5.61082 3.26514 8.5 3.26514V1.47115L12.4231 4.08654L8.5 6.36274V4.08245C6.06442 4.08245 4.08245 6.06442 4.08245 8.5C4.08245 10.9356 6.06442 12.9175 8.5 12.9175C10.9356 12.9175 12.9175 10.9519 12.9175 8.5H13.7308C13.7308 11.4014 11.3892 13.7349 8.5 13.7349Z"
      fill={color}
    />
  </svg>
)

Reload.propTypes = {
  color: string
}

export default Reload
