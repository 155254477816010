import React from 'react'
import { string } from 'prop-types'

const BodyMeasurement = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 18" fill="none" {...rest}>
    <path
      d="M1.26938 0.563777C1.19985 0.450271 1.19252 0.30754 1.25848 0.190369C1.32435 0.0732416 1.4451 0.00366446 1.58059 0.00366446L14.7375 0C14.8693 0 14.9938 0.0695771 15.056 0.186704C15.1219 0.303875 15.1182 0.44665 15.045 0.560113C14.3128 1.71327 13.9504 2.78224 13.7821 3.71576H3.01202C2.83622 3.71576 2.66783 3.75598 2.52144 3.82923C2.3274 2.70172 1.91002 1.58882 1.26938 0.563777Z"
      fill={color}
    />
    <path
      d="M15.5294 11.0226C10.547 11.9854 5.58291 11.9891 0.703125 11.0373C0.728685 11.0043 0.750671 10.9714 0.776323 10.9385C1.44619 10.0671 1.9441 9.0934 2.2553 8.07202C2.43467 8.19286 2.64703 8.25873 2.86296 8.25873H14.065C14.3798 9.22518 14.8557 10.1367 15.4707 10.9421C15.489 10.9677 15.511 10.9933 15.5294 11.0226Z"
      fill={color}
    />
    <path
      d="M16.2174 14.5994C16.2174 15.4011 16.1698 16.2906 16.0746 17.2388C16.0563 17.4291 15.8989 17.5719 15.7122 17.5719H8.4748V15.7122C8.4748 15.5109 8.30999 15.3461 8.10872 15.3461C7.90369 15.3461 7.74264 15.5109 7.74264 15.7122V17.5719H0.49058C0.303875 17.5719 0.139158 17.4255 0.120836 17.2351C0.040309 16.3346 0 15.4852 0 14.7238C0 13.4205 0.120836 12.4285 0.369744 11.7183C2.90674 12.2272 5.46564 12.4797 8.03919 12.4797C10.6346 12.4797 13.2448 12.2235 15.8586 11.7073C16.1003 12.3956 16.2174 13.351 16.2174 14.5994Z"
      fill={color}
    />
    <path
      d="M16.0346 4.448H3.01325C2.81913 4.448 2.65807 4.59806 2.64717 4.78843L2.49702 7.13496C2.48969 7.23748 2.52633 7.33633 2.59587 7.40952C2.6654 7.48277 2.76058 7.5267 2.8631 7.5267H4.68795V6.18518C4.68795 5.98283 4.85169 5.8191 5.05403 5.8191C5.25638 5.8191 5.42011 5.98283 5.42011 6.18518V7.5267H6.88443V6.00214C6.88443 5.79979 7.04817 5.63606 7.25051 5.63606C7.45285 5.63606 7.61659 5.79979 7.61659 6.00214V7.5267H9.08091V6.18518C9.08091 5.98283 9.24465 5.8191 9.44699 5.8191C9.64933 5.8191 9.81307 5.98283 9.81307 6.18518V7.5267H11.2774V6.00214C11.2774 5.79979 11.4411 5.63606 11.6435 5.63606C11.8458 5.63606 12.0095 5.79979 12.0095 6.00214V7.5267H13.4739V6.18518C13.4739 5.98283 13.6376 5.8191 13.8399 5.8191C14.0423 5.8191 14.206 5.98283 14.206 6.18518V7.5267H16.0346C16.236 7.5267 16.4007 7.36198 16.4007 7.16062V4.81408C16.4007 4.60905 16.236 4.448 16.0346 4.448Z"
      fill={color}
    />
  </svg>
)

BodyMeasurement.propTypes = {
  color: string
}

export default BodyMeasurement
