import React from 'react'
import { string } from 'prop-types'

const Arithmetic = ({ color, ...rest }) => (
  <svg viewBox="0 0 21 21" fill="none" {...rest}>
    <path
      d="M7.80588 1.49707H1.30017C0.774453 1.49707 0.314453 1.95707 0.314453 2.5485V9.05421C0.314453 9.57993 0.774453 10.0399 1.30017 10.0399H7.80588C8.3316 10.0399 8.7916 9.57993 8.7916 9.05421V2.5485C8.7916 1.95707 8.3316 1.49707 7.80588 1.49707ZM6.4916 6.09707H4.91445V7.73993C4.91445 7.93707 4.78302 8.0685 4.58588 8.0685C4.38874 8.0685 4.25731 7.87136 4.25731 7.73993V6.09707H2.61445C2.41731 6.09707 2.28588 5.96564 2.28588 5.7685C2.28588 5.57136 2.41731 5.43993 2.61445 5.43993H4.25731V3.79707C4.25731 3.59993 4.38874 3.4685 4.58588 3.4685C4.78302 3.4685 4.91445 3.59993 4.91445 3.79707V5.43993H6.55731C6.75445 5.43993 6.88588 5.57136 6.88588 5.7685C6.88588 5.96564 6.68874 6.09707 6.4916 6.09707Z"
      fill={color}
    />
    <path
      d="M7.80588 11.8799H1.30017C0.708739 11.8799 0.314453 12.3399 0.314453 12.8656V19.3713C0.314453 19.9627 0.774453 20.357 1.30017 20.357H7.80588C8.3316 20.357 8.7916 19.897 8.7916 19.3713V12.8656C8.7916 12.3399 8.3316 11.8799 7.80588 11.8799ZM6.16302 17.2685C6.29445 17.3999 6.29445 17.597 6.16302 17.7285C6.0316 17.8599 5.83445 17.8599 5.70302 17.7285L4.58588 16.6113L3.46874 17.7285C3.33731 17.8599 3.14017 17.8599 3.00874 17.7285C2.87731 17.597 2.87731 17.3999 3.00874 17.2685L4.12588 16.1513L2.94302 14.9685C2.8116 14.837 2.8116 14.6399 2.94302 14.5085C3.07445 14.377 3.2716 14.377 3.40302 14.5085L4.52017 15.6256L5.63731 14.5085C5.76874 14.377 5.96588 14.377 6.09731 14.5085C6.22874 14.6399 6.22874 14.837 6.09731 14.9685L4.98017 16.0856L6.16302 17.2685Z"
      fill={color}
    />
    <path
      d="M20.6195 7.41138L18.4509 1.29995C18.2538 0.774235 17.6624 0.511378 17.1366 0.708521L11.0252 2.87709C10.8281 2.94281 10.6309 3.13995 10.4995 3.33709C10.3681 3.53424 10.3681 3.86281 10.4338 4.12566L12.6024 10.2371C12.7338 10.6314 13.1281 10.8942 13.5881 10.8942C13.7195 10.8942 13.7852 10.8942 13.9166 10.8285L20.0281 8.65995C20.2909 8.59423 20.4881 8.39709 20.6195 8.13423C20.7509 7.87138 20.6852 7.67424 20.6195 7.41138ZM17.4652 5.43995L13.7852 6.75424C13.7195 6.75424 13.7195 6.75424 13.6538 6.75424C13.5224 6.75424 13.3909 6.68852 13.3252 6.55709C13.2595 6.35995 13.3252 6.22852 13.5224 6.16281L17.2024 4.84852C17.3995 4.78281 17.5309 4.84852 17.5966 5.04566C17.7281 5.17709 17.6624 5.37424 17.4652 5.43995Z"
      fill={color}
    />
    <path
      d="M18.7805 11.8799H12.2748C11.6833 11.8799 11.2891 12.3399 11.2891 12.8656V19.3713C11.2891 19.9627 11.7491 20.357 12.2748 20.357H18.7805C19.3062 20.357 19.7662 19.897 19.7662 19.3713V12.8656C19.7662 12.3399 19.3062 11.8799 18.7805 11.8799ZM15.4948 14.837H15.6262C15.8233 14.837 15.9548 14.9685 15.9548 15.1656C15.9548 15.3627 15.8233 15.4942 15.6262 15.4942H15.4948C15.2976 15.4942 15.1662 15.3627 15.1662 15.1656C15.1662 14.9685 15.2976 14.837 15.4948 14.837ZM15.6262 17.4656H15.4948C15.2976 17.4656 15.1662 17.3342 15.1662 17.137C15.1662 16.9399 15.2976 16.8085 15.4948 16.8085H15.6262C15.8233 16.8085 15.9548 16.9399 15.9548 17.137C15.9548 17.3342 15.8233 17.4656 15.6262 17.4656ZM17.4662 16.4799H13.5891C13.3919 16.4799 13.2605 16.3485 13.2605 16.1513C13.2605 15.9542 13.3919 15.8227 13.5891 15.8227H17.4662C17.6633 15.8227 17.7948 15.9542 17.7948 16.1513C17.7948 16.3485 17.6633 16.4799 17.4662 16.4799Z"
      fill={color}
    />
  </svg>
)

Arithmetic.propTypes = {
  color: string
}

export default Arithmetic
