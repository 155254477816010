import React from 'react'
import { string } from 'prop-types'

const Other = ({ color, ...rest }) => (
  <svg viewBox="0 0 11 11" fill="none" {...rest}>
    <path
      d="M10.5832 6.91736H7.00021V10.5624H3.58295V6.91736H0V3.66579H3.58295V0H7.00021V3.66579H10.5832V6.91736Z"
      fill={color}
    />
  </svg>
)

Other.propTypes = {
  color: string
}

export default Other
