import React from 'react'
import { string } from 'prop-types'

const FertilityPregnancy = ({ color, ...rest }) => (
  <svg viewBox="0 0 18 18" fill="none" {...rest}>
    <path
      d="M11.2824 8.94946C11.2824 8.94946 10.01 8.98827 9.65225 8.4314C9.29281 7.87452 9.49081 6.95934 9.27594 6.20221C9.05937 5.44509 9.08806 3.00271 11.4191 2.97346C14.1162 2.93915 14.8773 5.69596 14.2844 8.44209C13.6893 11.1882 12.3359 16.312 8.6555 15.8626C6.11019 15.5515 6.3695 13.5293 6.38019 12.5568C6.392 11.5842 6.113 11.4993 5.40594 11.4869C4.69887 11.4745 4.57513 10.7455 4.65331 10.4232C4.7315 10.1009 5.231 9.92877 5.38175 10.3C5.53363 10.6713 6.18388 10.2477 6.87013 10.3962C7.37975 10.5065 7.77125 11.1308 7.74369 11.7524C7.74369 11.7524 8.22069 10.3 9.128 10.0638C9.128 10.0638 7.66663 8.85046 7.77238 8.17096C7.87813 7.49202 8.48 7.83571 8.81581 8.22046C9.15219 8.60409 9.56844 9.50409 11.2824 8.94946Z"
      fill={color}
    />
    <path
      d="M9 0C4.03763 0 0 4.03763 0 9C0 13.9624 4.03763 18 9 18C13.9624 18 18 13.9624 18 9C18 4.03763 13.9624 0 9 0ZM9 16.9571C4.6125 16.9571 1.04288 13.3875 1.04288 9C1.04288 6.75956 1.9755 4.73344 3.47062 3.28669C5.12213 4.11919 5.08444 5.10806 5.03831 6.24206C5.00625 7.04925 4.9725 7.884 5.54456 8.66475C6.16219 9.50625 7.28156 10.1745 7.91775 10.5086C8.03756 10.3134 8.18606 10.116 8.36156 9.95456C7.812 9.675 6.67744 9.03375 6.10594 8.25412C5.67731 7.66969 5.70487 6.98962 5.73356 6.27019C5.77969 5.112 5.82581 3.81712 4.02413 2.79844C5.38762 1.70212 7.11788 1.044 9 1.044C13.3864 1.044 16.956 4.61306 16.956 9.00056C16.956 13.3881 13.3869 16.9571 9 16.9571Z"
      fill={color}
    />
  </svg>
)

FertilityPregnancy.propTypes = {
  color: string
}

export default FertilityPregnancy
