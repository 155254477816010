import React from 'react'
import { string, node, bool, object } from 'prop-types'
import styled, { css } from 'styled-components'

import { solitude, marineBlue, black, white } from '../colors'

const StyledCardHeader = styled.div`
  height: 54px;
  padding: 10px 12px 10px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  ${({ light }) =>
    light
      ? css`
          color: ${marineBlue};
          background-color: ${solitude};
        `
      : css`
          color: ${white};
          background-color: ${black};
        `}
`

const CardHeader = ({ title, action, light, style }) => (
  <StyledCardHeader light={light} style={style}>
    {title}
    {action}
  </StyledCardHeader>
)

CardHeader.propTypes = {
  title: string.isRequired,
  action: node,
  light: bool,
  style: object
}

export default CardHeader
