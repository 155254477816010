import React from 'react'
import { string } from 'prop-types'

const Macroeconomics = ({ color, ...rest }) => (
  <svg viewBox="0 0 18 15" fill="none" {...rest}>
    <path
      d="M13.4462 0.865226L14.4708 1.75322C13.0591 3.50644 9.12004 7.67319 0.900391 12.1359C0.900391 12.1359 9.91696 10.5421 16.5883 3.52921L17.5446 4.3489L18 0L13.4462 0.865226Z"
      fill={color}
    />
    <path
      d="M8.23242 14.0486H10.3044V9.0166C9.59857 9.42645 8.89273 9.79075 8.23242 10.1095V14.0486Z"
      fill={color}
    />
    <path
      d="M11.375 14.0485H13.447V6.94458C12.7639 7.46827 12.0581 7.92365 11.375 8.35627V14.0485Z"
      fill={color}
    />
    <path
      d="M14.5156 14.0486H16.5876V4.23511C15.9273 4.91818 15.2215 5.53295 14.5156 6.10218V14.0486Z"
      fill={color}
    />
    <path
      d="M5.08984 11.4301V14.0486H7.16183V10.5876C6.91137 10.7015 6.68368 10.7926 6.45599 10.8836C5.95507 11.0886 5.52246 11.2707 5.08984 11.4301Z"
      fill={color}
    />
    <path
      d="M1.94727 14.0486H4.01926V11.7944C3.1768 12.0677 2.47096 12.2726 1.94727 12.3864V14.0486Z"
      fill={color}
    />
  </svg>
)

Macroeconomics.propTypes = {
  color: string
}

export default Macroeconomics
