import React from 'react'
import { string } from 'prop-types'

const Percentages = ({ color, ...rest }) => (
  <svg viewBox="0 0 19 20" fill="none" {...rest}>
    <path
      d="M4.15625 9.50143C6.48731 9.50143 8.3125 7.41499 8.3125 4.75143C8.3125 2.08668 6.48731 0.000244141 4.15625 0.000244141C1.82519 0.000244141 0 2.08668 0 4.75024C0 7.41499 1.82519 9.50143 4.15625 9.50143ZM2.375 4.75024C2.375 3.44399 3.17775 2.37524 4.15625 2.37524C5.13713 2.37524 5.9375 3.44399 5.9375 4.75024V4.75143C5.9375 6.05768 5.13713 7.12643 4.15625 7.12643C3.17775 7.12643 2.375 6.05768 2.375 4.75024Z"
      fill={color}
    />
    <path
      d="M14.8438 9.5C12.5127 9.5 10.6875 11.5864 10.6875 14.25C10.6875 16.9136 12.5127 19.0012 14.8438 19.0012C17.1748 19.0012 19 16.9148 19 14.2512C19 11.5864 17.1748 9.5 14.8438 9.5ZM16.625 14.2512C16.625 15.5574 15.8246 16.6262 14.8438 16.6262C13.8653 16.6262 13.0625 15.5574 13.0625 14.2512V14.25C13.0625 12.9426 13.8653 11.875 14.8438 11.875C15.8246 11.875 16.625 12.9426 16.625 14.2512Z"
      fill={color}
    />
    <path
      d="M17.8134 8.3125L19.0009 0L10.6884 1.1875L13.4113 3.91044L0.529297 16.7936L2.20842 18.4728L15.0904 5.58956L17.8134 8.3125Z"
      fill={color}
    />
  </svg>
)

Percentages.propTypes = {
  color: string
}

export default Percentages
