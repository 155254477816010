import React from 'react'
import { string } from 'prop-types'

const ConstructionConverter = ({ color, ...rest }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.45496 10.2283L4.23606 9.47881C4.3432 9.37573 4.41002 9.17146 4.38378 9.02509L4.26041 8.33111L4.91191 7.7064L5.79717 7.97939L8.14046 5.73242C7.52224 5.08416 7.54442 4.05875 8.19132 3.43891C8.83741 2.81987 9.86336 2.84071 10.4846 3.48571L11.2659 2.73681C10.5376 1.78932 9.45428 1.17922 8.26654 1.04692C7.07933 0.914614 5.88943 1.26823 4.96791 2.03147L2.62462 4.2779L2.79561 5.23865L2.01424 5.98755L1.32541 5.83469C1.18012 5.80249 0.973413 5.86039 0.866273 5.96347L0.0849053 6.71237C-0.0225057 6.81545 -0.0260229 6.9878 0.0767886 7.09494L3.07266 10.2207C3.1752 10.3276 3.34701 10.3313 3.45496 10.2283ZM17.216 6.37174L15.6035 5.21024L14.9101 3.34692L17.5196 1.18301C16.4084 0.807744 15.1362 0.973866 14.1333 1.73846C12.8554 2.71246 12.4028 4.36421 12.8879 5.80682L11.455 7.23969L13.8478 9.4385L15.182 8.10439C16.2921 8.47721 17.5612 8.31109 18.562 7.54731C19.5653 6.78272 20.0617 5.60038 19.9941 4.4286L17.216 6.37174ZM6.67756 8.26293L8.40533 10.2899L6.79985 11.8954C5.68949 11.5229 4.4203 11.6887 3.41897 12.4525C2.41656 13.2168 1.91982 14.3997 1.98746 15.5707L4.76553 13.6275L6.37832 14.7898L7.07149 16.6529L4.46224 18.8163C5.57342 19.1921 6.84557 19.0259 7.84799 18.2614C9.12609 17.2873 9.57901 15.6356 9.0939 14.193L10.5181 12.7688L15.6874 18.8341C17.2187 18.7394 18.4576 17.552 18.6169 16.0252L8.38693 6.62471L6.67756 8.26293Z"
      fill={color}
    />
  </svg>
)

ConstructionConverter.propTypes = {
  color: string
}

export default ConstructionConverter
