import React from 'react'
import { string } from 'prop-types'

const Transportation = ({ color, ...rest }) => (
  <svg viewBox="0 0 23 11" fill="none" {...rest}>
    <path
      d="M0 4.9375L7.1875 3.5L8.625 0.625H17.25L20.125 3.5L23 4.9375V9.25H20.8438C20.8438 6.375 17.9688 6.375 17.9688 6.375C15.0938 6.375 15.0938 9.25 15.0938 9.25H8.625C8.625 6.375 5.75 6.375 5.75 6.375C2.875 6.375 2.875 9.25 2.875 9.25H0V4.9375Z"
      fill={color}
    />
    <path
      d="M5.75 10.6875C6.54391 10.6875 7.1875 10.0439 7.1875 9.25C7.1875 8.45609 6.54391 7.8125 5.75 7.8125C4.95609 7.8125 4.3125 8.45609 4.3125 9.25C4.3125 10.0439 4.95609 10.6875 5.75 10.6875Z"
      fill={color}
    />
    <path
      d="M17.9688 10.6875C18.7627 10.6875 19.4062 10.0439 19.4062 9.25C19.4062 8.45609 18.7627 7.8125 17.9688 7.8125C17.1748 7.8125 16.5312 8.45609 16.5312 9.25C16.5312 10.0439 17.1748 10.6875 17.9688 10.6875Z"
      fill={color}
    />
  </svg>
)

Transportation.propTypes = {
  color: string
}

export default Transportation
