import React, { useState } from 'react'
import { string, func, shape, arrayOf } from 'prop-types'
import styled, { css } from 'styled-components'

import { lightBlue, lightBlueOpaque } from '@/style-guide/colors'

const Container = styled.div``

const TabsContainer = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
`

const TabItem = styled.li`
  opacity: 0.5;
  cursor: pointer;
  padding: 5px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${lightBlueOpaque};

  & + li {
    margin-left: 5px;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      background-color: ${lightBlue};
    `}
`

const TabContent = styled.div``

const Tab = ({ panes = [], onTabChange }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const handleChangeTab = tabIndex => {
    setActiveTabIndex(tabIndex)

    onTabChange(tabIndex)
  }

  return (
    <Container>
      <TabsContainer>
        {panes.map(({ menuItem }, index) => (
          <TabItem
            key={`${menuItem}-${index}`}
            active={index === activeTabIndex}
            onClick={() => handleChangeTab(index)}
          >
            {menuItem}
          </TabItem>
        ))}
      </TabsContainer>
      <TabContent>{panes[activeTabIndex].render()}</TabContent>
    </Container>
  )
}

Tab.propTypes = {
  panes: arrayOf(
    shape({
      menuItem: string,
      render: func
    })
  ).isRequired,
  onTabChange: func.isRequired
}

export default Tab
