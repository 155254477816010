import React from 'react'
import { string } from 'prop-types'

const Tax = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 17" fill="none" {...rest}>
    <path
      d="M6.77517 4.19961H6.03011V6.52088H5.3722V4.19961H4.63281V3.69482H6.77517V4.19961Z"
      fill={color}
    />
    <path
      d="M8.92851 5.97072H8.03805L7.87821 6.52036H7.21875L8.13138 3.69482H8.8357L9.74987 6.52036H9.08989L8.92851 5.97072ZM8.18345 5.46646H8.78311L8.48818 4.45535H8.47684L8.18345 5.46646Z"
      fill={color}
    />
    <path
      d="M11.5804 4.64767L12.046 3.69482H12.8034L11.9944 5.09573L12.8658 6.52088H12.0697L11.5886 5.54792L11.107 6.52088H10.3496L11.1803 5.09573L10.3713 3.69482H11.1225L11.5804 4.64767Z"
      fill={color}
    />
    <path d="M14.066 7.66235H3.43359V8.05989H14.066V7.66235Z" fill={color} />
    <path d="M11.7551 9.41821H5.74414V9.81575H11.7551V9.41821Z" fill={color} />
    <path
      d="M15.5094 5.83308V3.03848C15.5099 2.78068 15.4099 2.53679 15.2263 2.35375C15.0438 2.17071 14.8009 2.06965 14.539 2.06965L11.1473 2.06862L8.74974 0L6.35319 2.06862L2.96048 2.06759C2.4258 2.06759 1.99011 2.5038 1.99011 3.03951V5.83308L0.5 7.11901V15.4662C0.5 16.0148 0.943939 16.4603 1.49616 16.4603H16.0059C16.5555 16.4603 17.0015 16.0143 17 15.4652V7.11901L15.5094 5.83308ZM8.74974 1.05339L9.92636 2.06862H7.57364L8.74974 1.05339ZM1.20432 7.56295L1.98959 6.88544V8.05845L1.20432 7.56295ZM5.70713 11.9585L1.85657 15.3507C1.81841 15.3837 1.77149 15.4012 1.72457 15.4012C1.66992 15.4012 1.61475 15.378 1.57504 15.3337C1.50234 15.2512 1.51059 15.1254 1.59257 15.0517L5.44314 11.66C5.52615 11.5883 5.65145 11.5955 5.72466 11.678C5.79685 11.7605 5.78911 11.8863 5.70713 11.9585ZM2.52119 8.39359V3.03951C2.52119 2.79666 2.71866 2.59918 2.96048 2.59918L14.5426 2.60021C14.6591 2.60021 14.7684 2.64662 14.8515 2.72809C14.9334 2.81161 14.9793 2.92041 14.9788 3.03848V8.39308L8.74974 12.3205L2.52119 8.39359ZM15.9244 15.3332C15.8847 15.3775 15.8296 15.4007 15.7749 15.4007C15.728 15.4007 15.6811 15.3837 15.6429 15.3502L11.7923 11.9585C11.7104 11.8858 11.7021 11.76 11.7748 11.6775C11.848 11.595 11.9733 11.5878 12.0563 11.6595L15.9069 15.0511C15.9889 15.1249 15.9971 15.2507 15.9244 15.3332ZM15.5094 8.05742V6.88441L16.2946 7.56192L15.5094 8.05742Z"
      fill={color}
    />
  </svg>
)

Tax.propTypes = {
  color: string
}

export default Tax
