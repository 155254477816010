import React from 'react'
import { string } from 'prop-types'

const PersonalFinanceDay = ({ color, ...rest }) => (
  <svg viewBox="0 0 16 16" fill="none" {...rest}>
    <path
      d="M15.8309 5.30583C15.1194 3.01329 12.9843 0.982795 8.85788 0.800186C8.76149 0.403484 8.58276 0 8.24999 0C7.91722 0 7.73801 0.403484 7.6421 0.800186C3.51572 0.983279 1.3806 3.01329 0.668084 5.30535C0.668084 5.30535 0.338225 5.94521 0.602693 6.0881C0.82599 6.20774 1.1171 5.79554 1.20622 5.657C1.73177 5.50007 2.35613 5.44243 3.03425 5.44243C3.89111 5.44243 4.66708 5.52768 5.25704 5.79941C5.31275 6.04789 5.42028 6.31236 5.6305 6.31236C5.83442 6.31236 5.94146 6.05758 5.99814 5.81346C6.59246 5.53058 7.38005 5.44243 8.24999 5.44243C9.1083 5.44243 9.88669 5.52865 10.4771 5.80183C10.5328 6.04983 10.6409 6.31236 10.8501 6.31236C11.0555 6.31236 11.1625 6.05564 11.2192 5.81104C11.8126 5.5301 12.5987 5.44243 13.4662 5.44243C14.1443 5.44243 14.7682 5.50007 15.2938 5.657C15.3829 5.79408 15.674 6.20774 15.8973 6.0881C16.1622 5.94569 15.8309 5.30583 15.8309 5.30583Z"
      fill={color}
    />
    <path
      d="M2.83203 9.25598V14.7856C2.83203 15.0946 3.06162 15.345 3.34498 15.345H4.35442V8.69604H3.34498C3.06211 8.69604 2.83203 8.94695 2.83203 9.25598Z"
      fill={color}
    />
    <path
      d="M13.1545 8.69604H12.1445V15.345H13.1545C13.4378 15.345 13.6669 15.0941 13.6669 14.7856V9.25598C13.6669 8.94695 13.4378 8.69604 13.1545 8.69604Z"
      fill={color}
    />
    <path
      d="M6.77761 7.90166C6.77761 7.62605 7.00139 7.40179 7.27748 7.40179H9.22176C9.49785 7.40179 9.72212 7.62605 9.72212 7.90166V8.35843H10.4153V7.34173C10.4153 6.93582 10.0864 6.60645 9.67949 6.60645H6.81975C6.41336 6.60645 6.08398 6.93582 6.08398 7.34173V8.35843H6.77761V7.90166Z"
      fill={color}
    />
    <path
      d="M5.46512 8.29478C5.46512 8.20226 5.39004 8.12573 5.29656 8.12573H4.15489C4.06141 8.12573 3.98633 8.20178 3.98633 8.29478V8.51953H5.46512V8.29478Z"
      fill={color}
    />
    <path
      d="M11.0332 8.29478C11.0332 8.20226 11.1088 8.12573 11.2027 8.12573H12.3434C12.4369 8.12573 12.513 8.20178 12.513 8.29478V8.51953H11.0332V8.29478Z"
      fill={color}
    />
    <path
      d="M10.4165 8.69604H9.72338H6.77887H6.08525H5.03125V15.345H11.4705V8.69604H10.4165ZM9.50008 13.4928C9.26468 13.7166 8.94112 13.8512 8.52843 13.8973V14.4005H7.97092V13.8973C7.26954 13.8144 6.82876 13.4453 6.64906 12.79L7.66576 12.6006C7.75295 12.8994 7.97237 13.0491 8.32548 13.0491C8.60012 13.0491 8.73865 12.974 8.73865 12.8219C8.73865 12.7425 8.7062 12.6805 8.64226 12.6364C8.57832 12.5919 8.46498 12.5526 8.30223 12.5211C7.66382 12.3899 7.2584 12.2242 7.08645 12.0237C6.91498 11.8212 6.82925 11.5902 6.82925 11.3291C6.82925 11.0404 6.92321 10.7842 7.11164 10.5609C7.29957 10.3366 7.58584 10.1957 7.97188 10.1375V9.64106H8.5294V10.1264C9.15473 10.1962 9.56305 10.4936 9.75535 11.0191L8.8457 11.3034C8.7527 11.0574 8.56282 10.9343 8.27656 10.9343C8.03582 10.9343 7.91667 11.0114 7.91667 11.1664C7.91667 11.2221 7.93653 11.2695 7.9777 11.3102C8.0179 11.3499 8.13028 11.3906 8.31434 11.4318C8.73477 11.5229 9.03557 11.6047 9.21769 11.6783C9.39982 11.751 9.55094 11.8735 9.67301 12.0474C9.7941 12.2203 9.85368 12.4243 9.85368 12.6616C9.85319 12.9915 9.73501 13.2695 9.50008 13.4928Z"
      fill={color}
    />
  </svg>
)

PersonalFinanceDay.propTypes = {
  color: string
}

export default PersonalFinanceDay
