import React from 'react'
import { string } from 'prop-types'

const Coordinate = ({ color, ...rest }) => (
  <svg viewBox="0 0 19 16" fill="none" {...rest}>
    <path
      d="M14.4692 0L9.5 3.30308L5.32 6.08L8.21385 8.00923L9.5 8.85692L18.6492 2.77692L14.4692 0ZM4.53077 0L0.350769 2.77692L4.53077 5.55385L8.71077 2.77692L4.53077 0ZM0 3.59538V9.17846L4.09231 11.8969V6.31385L0 3.59538ZM4.96923 12.4815L9.06154 15.2V9.64615L4.96923 6.92769V12.4815ZM19 3.59538L10.1431 9.5L9.93846 9.64615V15.2292L19 9.17846V3.59538Z"
      fill={color}
    />
  </svg>
)

Coordinate.propTypes = {
  color: string
}

export default Coordinate
