import React from 'react'
import { string } from 'prop-types'

const VideoDownloader = ({ color, ...rest }) => (
  <svg viewBox="0 0 23 22" fill="none" {...rest}>
    <path
      d="M20.4439 7.75847C20.3032 7.43032 19.9751 7.21936 19.6235 7.21936H15.545V0.890701C15.545 0.398471 15.1465 0 14.6543 0H8.46628C7.97405 0 7.57557 0.398471 7.57557 0.890701V7.21936H3.02831C2.67672 7.21936 2.34857 7.43032 2.20793 7.75847C2.06729 8.08663 2.13761 8.4851 2.39545 8.74293L10.904 17.6499C11.0681 17.8375 11.3025 17.9312 11.5603 17.9312C11.8181 17.9312 12.0525 17.814 12.2166 17.6265L20.3267 8.71949C20.5376 8.46166 20.5845 8.08663 20.4439 7.75847Z"
      fill={color}
    />
    <path
      d="M22.0859 21.7282H1.03719C0.52152 21.7282 0.0996094 21.2126 0.0996094 20.5563C0.0996094 19.8999 0.52152 19.3843 1.03719 19.3843H22.0624C22.5781 19.3843 23 19.8999 23 20.5563C23 21.2126 22.6015 21.7282 22.0859 21.7282Z"
      fill={color}
    />
  </svg>
)

VideoDownloader.propTypes = {
  color: string
}

export default VideoDownloader
