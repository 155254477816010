import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'
import Row from './Row'
import Col from './Col'

const StyledGrid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
`

const Grid = ({ children }) => <StyledGrid>{children}</StyledGrid>

Grid.propTypes = {
  children: node
}

Grid.Row = Row
Grid.Col = Col
export default Grid
