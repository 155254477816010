import React from 'react'
import { string, node } from 'prop-types'
import styled from 'styled-components'

import Separator from '../Separator'
import Label from '../Label'

import { lightBlue, darkBlue } from '../colors'

const StyledFormula = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Formula = ({ title, children }) => (
  <StyledFormula>
    <Label color={darkBlue} style={{ marginBottom: 4 }}>
      {title}
    </Label>
    <Separator fontSize={20} backgroundColor={lightBlue}>
      {children}
    </Separator>
  </StyledFormula>
)

Formula.propTypes = {
  title: string,
  children: node
}

export default Formula
