import React from 'react'
import { string } from 'prop-types'

const Dietary = ({ color, ...rest }) => (
  <svg viewBox="0 0 16 19" fill="none" {...rest}>
    <path
      d="M12.2605 3.36677C9.38365 3.03483 9.65674 4.05284 8.43072 4.25179C8.70845 3.25751 9.44564 1.54251 11.2671 1.21461C11.5999 1.15473 11.821 0.836476 11.7611 0.503739C11.7013 0.171002 11.3827 -0.0498967 11.0503 0.00971337C9.59907 0.270974 8.44624 1.19866 7.71635 2.69252C7.4462 3.24553 7.28269 3.77829 7.18653 4.17369C6.22217 3.8708 6.0637 3.07503 4.362 3.41185C2.11563 3.85647 1.11976 5.26437 1.11976 5.26437C1.11976 5.26437 -0.16571 6.47642 0.0179621 9.53701C0.201594 12.5976 3.69069 19.1597 6.8738 18.3027C7.80835 18.0511 8.45327 18.2038 9.56717 18.3639C12.1993 18.7424 15.8958 12.617 15.9901 9.36802C16.0845 6.11895 14.5031 3.62553 12.2605 3.36677ZM3.54028 12.2228C3.41982 12.2965 3.28667 12.3316 3.15511 12.3316C2.90623 12.3316 2.66327 12.2059 2.52385 11.9781C1.80285 10.7999 1.52532 9.45883 1.72125 8.09994C1.86576 7.09776 2.23485 6.33743 2.39098 6.12622C2.63362 5.79788 3.09653 5.72851 3.42483 5.97116C3.74546 6.20817 3.8192 6.65525 3.59635 6.98183C3.54047 7.08248 2.48396 9.08041 3.78495 11.2064C3.99803 11.5547 3.88849 12.0098 3.54028 12.2228Z"
      fill={color}
    />
  </svg>
)

Dietary.propTypes = {
  color: string
}

export default Dietary
