import React from 'react'
import { number, string, oneOfType, func, object } from 'prop-types'
import styled from 'styled-components'

import { lightBlue, black, lightGrey } from '../colors'

import Label from '../Label'

const StyledTextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
`

const StyledTextAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${lightBlue};
`

const StyledTextArea = styled.textarea`
  font-weight: bold;
  font-size: 16px;
  color: ${black};
  padding: 5px 10px;
  height: 35px;
  border: 0;
  outline: 0;
  flex: 1;
  width: 100%;
  height: 203px;

  :active,
  :focus {
    background-color: ${lightGrey};
  }

  &[type='number'] {
    font-size: 24px;
  }
`

const TextArea = ({
  value,
  onChange,
  label,
  className,
  styleLabel,
  styleWrapper,
  ...textAreaProps
}) => (
  <StyledTextAreaContainer className={className}>
    {label && (
      <Label style={styleLabel} light>
        {label}
      </Label>
    )}
    <StyledTextAreaWrapper style={styleWrapper}>
      <StyledTextArea
        {...textAreaProps}
        value={value}
        onChange={({ target }) => onChange(target.value)}
      />
    </StyledTextAreaWrapper>
  </StyledTextAreaContainer>
)

TextArea.propTypes = {
  value: oneOfType([number, string]),
  onChange: func,
  label: string,
  className: string,
  styleLabel: object,
  styleWrapper: object
}

TextArea.defaultProps = {
  onChange: () => {}
}

export default TextArea
