import React from 'react'
import { string, object, node } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 25px;
  }

  ${media.greaterThan('medium')`
    flex-direction: row;

    > * {
      margin-bottom: unset;
    }

    :not(:last-child) {
      margin-bottom: 25px;
    }
  }
  `}
`

const Row = ({ children, className, style }) => (
  <StyledRow className={className} style={style}>
    {children}
  </StyledRow>
)

Row.propTypes = {
  style: object,
  className: string,
  children: node
}

export default Row
