import React from 'react'
import { string } from 'prop-types'

const RealState = ({ color, ...rest }) => (
  <svg viewBox="0 0 16 16" fill="none" {...rest}>
    <path
      d="M16.0007 16V2.08298L11.1858 0.5L6.63477 2.5117V4.55638L8.15179 3.86383L12.7688 5.47979V16H16.0007ZM13.8901 2.87447H14.5826V4.25957H13.8901V2.87447ZM13.8901 5.34787H14.5826V6.73298H13.8901V5.34787ZM13.8901 7.82128H14.5826V9.20638H13.8901V7.82128ZM13.8901 10.2947H14.5826V11.6798H13.8901V10.2947ZM13.8901 12.8011H14.5826V14.1862H13.8901V12.8011Z"
      fill={color}
    />
    <path d="M8.51367 15.9999H10.9871H12.0754V5.97438L8.51367 4.72119V15.9999Z" fill={color} />
    <path
      d="M0.5 16.0001H7.7883V4.82031L0.5 8.11818V16.0001ZM1.5883 8.8767L6.20532 7.42563V8.15116L1.5883 9.60223V8.8767ZM1.5883 10.7565L6.20532 9.8001V10.5256L1.5883 11.482V10.7565ZM1.5883 12.6363L6.20532 12.1416V12.8671L1.5883 13.3618V12.6363ZM1.5883 14.5161H6.20532V15.2086H1.5883V14.5161Z"
      fill={color}
    />
  </svg>
)

RealState.propTypes = {
  color: string
}

export default RealState
