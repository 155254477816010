import React from 'react'
import { string } from 'prop-types'

const HighSchoolGpa = ({ color, ...rest }) => (
  <svg viewBox="0 0 18 15" fill="none" {...rest}>
    <path
      d="M16.6053 8.06688L16.3208 7.69697L15.7659 6.94292H11.8534V4.6381L9.02215 3.24382V2.47555C9.44897 2.43287 9.9327 2.63205 10.3311 2.74587C10.6441 2.84546 10.9713 2.88814 11.2843 2.88814C11.6827 2.88814 12.081 2.817 12.4652 2.6605V0.199182C11.7822 0.469501 11.0282 0.497955 10.3311 0.284546C9.97538 0.170728 9.54856 0 9.1502 0H8.65224V3.24382L5.80678 4.6381V6.94292H1.88005L1.32518 7.69697L1.04064 8.06688L0.5 8.77824V14.5261H8.82297H17.1459V8.79247L16.6053 8.06688ZM4.86778 13.5444H2.33532V11.8656H4.86778V13.5444ZM4.86778 10.7701H2.33532V9.10547H4.86778V10.7701ZM10.4022 13.5017H8.82297H7.24374V10.6136C7.24374 9.7457 7.94088 9.04856 8.80874 9.04856H8.82297H8.83719C9.70506 9.04856 10.4022 9.7457 10.4022 10.6136V13.5017ZM15.3106 13.5444H12.7782V11.8656H15.3106V13.5444ZM15.3106 10.7701H12.7782V9.10547H15.3106V10.7701Z"
      fill={color}
    />
  </svg>
)

HighSchoolGpa.propTypes = {
  color: string
}

export default HighSchoolGpa
