import React from 'react'
import { string } from 'prop-types'

const ArrowDown = ({ color, ...rest }) => (
  <svg viewBox="0 0 8 5" {...rest}>
    <path
      d="M6.54777 0.418945L7.25391 1.12291L3.76679 4.60349L0.279672 1.12291L0.985813 0.418945L3.76679 3.19338L6.54777 0.418945Z"
      fill={color}
    />
  </svg>
)

ArrowDown.propTypes = {
  color: string
}

export default ArrowDown
