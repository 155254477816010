import React from 'react'
import { string } from 'prop-types'

const DiceRoller = ({ color, ...rest }) => (
  <svg viewBox="0 0 25 23" fill="none" {...rest}>
    <path
      d="M24.6346 6.99026L17.8768 0.233295C17.5658 -0.077765 17.0619 -0.077765 16.7508 0.233295L9.99397 6.99026C9.72024 7.26384 9.68794 7.68624 9.89538 7.9958H1.77483C1.33467 7.9958 0.978516 8.35191 0.978516 8.79212V21.5333C0.978516 21.9734 1.33467 22.3296 1.77483 22.3296H14.5158C14.956 22.3296 15.3122 21.9734 15.3122 21.5333V13.4334L16.7508 14.8717C17.0619 15.1828 17.5658 15.1828 17.8768 14.8717L24.6346 8.11635C24.9457 7.80524 24.9457 7.30132 24.6346 6.99026ZM4.16471 12.7418C3.28504 12.7418 2.57209 12.0287 2.57209 11.1492C2.57209 10.2696 3.28504 9.55658 4.16471 9.55658C5.04409 9.55658 5.75734 10.2696 5.75734 11.1492C5.75734 12.0286 5.04409 12.7418 4.16471 12.7418ZM8.14533 16.7554C7.26601 16.7554 6.55271 16.0429 6.55271 15.1626C6.55271 14.2822 7.26601 13.5699 8.14533 13.5699C9.02466 13.5699 9.73701 14.2822 9.73701 15.1626C9.73701 16.0429 9.02466 16.7554 8.14533 16.7554ZM12.1278 20.704C11.2482 20.704 10.5352 19.9919 10.5352 19.1113C10.5352 18.2313 11.2482 17.5187 12.1278 17.5187C13.0072 17.5187 13.7195 18.2313 13.7195 19.1113C13.7195 19.9919 13.0072 20.704 12.1278 20.704ZM17.3138 8.74777C16.6544 8.74777 16.1194 8.21275 16.1194 7.5533C16.1194 6.89386 16.6544 6.35883 17.3138 6.35883C17.9733 6.35883 18.5083 6.89386 18.5083 7.5533C18.5083 8.21275 17.9733 8.74777 17.3138 8.74777Z"
      fill={color}
    />
  </svg>
)

DiceRoller.propTypes = {
  color: string
}

export default DiceRoller
