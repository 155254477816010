import React, { useState } from 'react'
import { string, node, bool, object } from 'prop-types'
import styled, { css } from 'styled-components'

import Separator from '../Separator'
import Icon from '../Icon'

const Content = styled.div`
  overflow: hidden;
  max-height: 0px;

  ${({ isOpened }) =>
    isOpened &&
    css`
      overflow: initial;
      max-height: 250px;
    `}
`

const ArrowIcon = styled(Icon)`
  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: rotate(180deg);
    `}
`

const Title = styled(Separator)`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const Accordion = ({ title, content, opened = true, className, style, contentStyle }) => {
  const [isOpened, setIsOpened] = useState(opened)

  const handleToggleAccordion = () => setIsOpened(!isOpened)

  return (
    <div className={className} style={style}>
      <Title onClick={handleToggleAccordion}>
        {title}
        <ArrowIcon name="arrow-down" isOpened={isOpened} />
      </Title>
      <Content isOpened={isOpened} style={contentStyle}>
        {content}
      </Content>
    </div>
  )
}

Accordion.propTypes = {
  title: string,
  content: node,
  opened: bool,
  className: string,
  style: object,
  contentStyle: object
}

export default Accordion
