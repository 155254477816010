import React from 'react'
import { string } from 'prop-types'

const Bandwidth = ({ color, ...rest }) => (
  <svg viewBox="0 0 22 22" fill="none" {...rest}>
    <path
      d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM11 20.02C6.01333 20.02 1.98 15.9622 1.98 11C1.98 6.01333 6.03778 1.98 11 1.98C15.9622 1.98 20.02 6.01333 20.02 11C20.02 15.9867 15.9867 20.02 11 20.02Z"
      fill={color}
    />
    <path
      d="M10.9991 3.10449C6.64796 3.10449 3.10352 6.64894 3.10352 11C3.10352 15.3512 6.64796 18.8956 10.9991 18.8956C15.3502 18.8956 18.8946 15.3512 18.8946 11C18.8946 6.64894 15.3502 3.10449 10.9991 3.10449ZM16.7924 11H12.9302C12.808 11 12.6857 10.9023 12.6857 10.78C12.6613 10.6334 12.6124 10.4623 12.5635 10.34C12.5391 10.2912 12.5391 10.2178 12.5635 10.1445L13.2235 8.26227C13.2724 8.14005 13.1257 8.04227 13.028 8.1156L11.488 9.28894C11.4391 9.33782 11.3413 9.33783 11.268 9.33783C11.1702 9.31338 11.0968 9.31338 10.9991 9.31338C10.1435 9.31338 9.43463 9.94894 9.33685 10.78C9.3124 10.9023 9.21463 11 9.0924 11H5.20574C5.05907 11 4.93685 10.8778 4.93685 10.7312C5.08352 7.50449 7.74796 4.93783 10.9991 4.93783C14.2502 4.93783 16.9146 7.50449 17.0613 10.7312C17.0613 10.8778 16.9391 11 16.7924 11Z"
      fill={color}
    />
  </svg>
)

Bandwidth.propTypes = {
  color: string
}

export default Bandwidth
