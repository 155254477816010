import React from 'react'
import { string } from 'prop-types'

const Search = ({ color, ...rest }) => (
  <svg viewBox="0 0 25 25" fill="none" {...rest}>
    <path
      d="M24.0275 21.3675L17.2426 14.5891C19.6628 10.9386 19.2662 5.97046 16.049 2.75398C12.3783 -0.917434 6.42524 -0.91855 2.75378 2.75398C-0.917926 6.42509 -0.917926 12.379 2.75378 16.0496C5.96985 19.2668 10.9351 19.6635 14.5855 17.2451L21.3668 24.0283C21.3668 24.0283 22.6559 25.3981 24.0276 24.0283C25.397 22.6566 24.0275 21.3675 24.0275 21.3675ZM13.39 13.3907C11.1902 15.5905 7.61244 15.5905 5.41257 13.3907C3.2127 11.1909 3.2127 7.61276 5.41257 5.41288C7.61244 3.21301 11.1902 3.21301 13.39 5.41288C15.5899 7.61276 15.5899 11.1909 13.39 13.3907Z"
      fill={color}
    />
  </svg>
)

Search.propTypes = {
  color: string
}

export default Search
