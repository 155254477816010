import React from 'react'
import { string } from 'prop-types'

const EveryDay = ({ color, ...rest }) => (
  <svg viewBox="0 0 21 21" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9186 9.62578H18.8346C18.4705 8.85571 17.9348 8.14843 17.261 7.5374C17.374 6.47856 18.2905 5.59131 18.2905 5.59131C16.8927 5.59968 16.2691 6.19815 16.0013 6.59574C15.3275 6.18141 14.5741 5.84242 13.7622 5.59549C13.4149 7.02681 12.0756 8.0982 10.4769 8.0982C8.8991 8.0982 7.57241 7.04774 7.20412 5.64153C4.01086 6.66689 1.75508 9.11938 1.75089 11.982C1.61278 11.9946 1.47049 11.9946 1.33238 11.9695C1.19845 11.9485 1.06034 11.9067 0.934791 11.8481C0.725534 11.7518 0.545573 11.5928 0.394909 11.4212C0.319576 11.3333 0.252614 11.2329 0.194022 11.1282C0.160541 11.0697 0.122875 11.0111 0.093579 10.9525C0.064283 10.9022 0.0140614 10.8185 0.0182465 10.7599C0.0140614 10.8939 -0.0110494 11.032 0.00569112 11.1659C0.0559127 11.5635 0.214948 11.9527 0.478611 12.2582C0.750645 12.5679 1.14823 12.8316 1.53745 12.9697C1.64626 13.0074 1.75926 13.0408 1.87226 13.066C2.16941 14.5391 3.05665 15.8616 4.35405 16.8702V19.3604C4.35405 19.9128 4.80604 20.3648 5.35848 20.3648H6.75213C7.30456 20.3648 7.75656 19.9128 7.75656 19.3604V18.5066C8.63962 18.741 9.58546 18.8707 10.5732 18.8707C11.4646 18.8707 12.3225 18.7661 13.1345 18.5736V19.3562C13.1345 19.9087 13.5865 20.3606 14.1389 20.3606H15.5325C16.085 20.3606 16.537 19.9087 16.537 19.3562V17.0502C17.5581 16.3178 18.3533 15.4013 18.843 14.3633H19.9311C20.4836 14.3633 20.9355 13.9114 20.9355 13.3589V10.6302C20.923 10.0778 20.471 9.62578 19.9186 9.62578ZM16.2189 9.92711C15.9092 9.92711 15.6581 9.65926 15.6581 9.33282C15.6581 9.00638 15.9092 8.73853 16.2189 8.73853C16.5286 8.73853 16.7797 9.00638 16.7797 9.33282C16.7797 9.65926 16.5286 9.92711 16.2189 9.92711Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1641 2.05488C12.8421 2.43154 13.3694 3.02583 13.6456 3.74148C13.6498 3.74148 13.6498 3.74148 13.6498 3.74148C13.8716 3.74148 14.0181 3.61593 14.0181 3.44016C14.0181 3.26856 13.8967 3.16394 13.6665 3.07186C13.3526 2.95049 13.1559 2.8082 13.1559 2.53617C13.1559 2.28087 13.3401 2.08417 13.6247 2.03814V1.72425H13.8172V2.02558C14.0013 2.02977 14.1311 2.07999 14.219 2.13439L14.1436 2.33946C14.0767 2.3018 13.9511 2.23484 13.7544 2.23484C13.5159 2.23484 13.428 2.37713 13.428 2.4985C13.428 2.66172 13.541 2.74124 13.813 2.85005C14.1311 2.97979 14.2943 3.14301 14.2943 3.41504C14.2943 3.66197 14.1227 3.88796 13.8046 3.94237V4.26463H13.8004C13.8297 4.41529 13.8465 4.57433 13.8549 4.73336C14.8007 4.66221 15.5457 3.91726 15.5457 2.99653C15.5457 2.02977 14.7254 1.24296 13.7126 1.24296C13.0555 1.23877 12.4863 1.56522 12.1641 2.05488Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8817 1.91679C11.8858 1.90424 11.89 1.89168 11.8984 1.87913C11.9654 1.77868 12.0449 1.68242 12.1286 1.59454C12.0867 1.52339 12.0114 1.46898 11.89 1.42295C11.6431 1.32669 11.4924 1.21369 11.4924 1.00443C11.4924 0.803546 11.6347 0.652881 11.8565 0.615215V0.372477H12.0072V0.606844C12.1537 0.61103 12.2499 0.648696 12.3211 0.690547L12.2625 0.849582C12.2123 0.820286 12.1118 0.770065 11.957 0.770065C11.7728 0.770065 11.7017 0.878878 11.7017 0.979321C11.7017 1.10488 11.7896 1.16765 12.003 1.25554C12.1328 1.30995 12.229 1.37272 12.2918 1.44806C12.5764 1.20532 12.9196 1.0421 13.2879 0.970951C13.1079 0.410143 12.568 0 11.9235 0C11.1325 0 10.4922 0.61103 10.4922 1.36854C10.4922 1.45643 10.5047 1.54013 10.5173 1.62383C11.0028 1.62383 11.4673 1.73265 11.8817 1.91679Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4773 7.78019C12.1681 7.78019 13.5366 6.47443 13.5366 4.85897C13.5366 3.24351 12.1681 1.93774 10.4773 1.93774C8.78651 1.93774 7.41797 3.24351 7.41797 4.85897C7.42215 6.47443 8.79069 7.78019 10.4773 7.78019ZM10.4103 4.98452C9.88301 4.77945 9.55657 4.5409 9.55657 4.09309C9.55657 3.6662 9.86209 3.33976 10.335 3.26025V2.7371H10.6573V3.23932C10.967 3.24769 11.1762 3.33139 11.3269 3.41928L11.1971 3.75828C11.0883 3.6955 10.8791 3.5825 10.5484 3.5825C10.1509 3.5825 10.0002 3.82105 10.0002 4.02613C10.0002 4.29397 10.1927 4.4279 10.6405 4.61623C11.172 4.83386 11.4399 5.10171 11.4399 5.56207C11.4399 5.96803 11.1553 6.35306 10.6238 6.44095V6.97665H10.2973V6.4535C9.99601 6.44932 9.6905 6.35725 9.50635 6.22751L9.6319 5.88014C9.81605 6.00569 10.0881 6.10195 10.3769 6.10195C10.7452 6.10195 10.9921 5.88851 10.9921 5.59555C10.9963 5.31515 10.7954 5.13937 10.4103 4.98452Z"
      fill={color}
    />
  </svg>
)

EveryDay.propTypes = {
  color: string
}

export default EveryDay
