import React from 'react'
import { string } from 'prop-types'

const HomeAndGarden = ({ color, ...rest }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.4091 17.9393C19.9217 17.9393 18.901 10.7825 18.901 10.7825L11.9173 10.7825C11.9173 10.7825 10.8966 17.9393 15.4091 17.9393Z"
      fill={color}
    />
    <path
      d="M16.0469 0.636911L16.0468 11.4111H14.7731V0.636911C14.7731 0.285119 15.0582 0 15.41 0C15.7617 0 16.0469 0.285119 16.0469 0.636911Z"
      fill={color}
    />
    <path
      d="M0.99888 2.65018L0.998916 0.393794C0.99888 0.176055 1.16216 3.52974e-05 1.36331 3.52974e-05C1.56474 0.000105889 1.72788 0.17609 1.72788 0.39383V2.13264L3.22505 2.1326V0.39383C3.22505 0.17609 3.38833 7.05905e-05 3.58973 7.05905e-05C3.79074 3.52948e-05 3.95408 0.17609 3.95408 0.39383V2.1326L5.45147 2.13257V0.393794C5.45147 0.17609 5.61447 7.05931e-05 5.81583 3.52974e-05C6.01712 7.05931e-05 6.18015 0.176055 6.18022 0.39383L6.18019 2.1326L7.67757 2.13257V0.39383C7.67757 0.17609 7.84085 3.52948e-05 8.04193 7.05905e-05C8.24336 7.05905e-05 8.40668 0.17609 8.40668 0.39383L8.40671 2.1326H9.90378L9.90371 0.39383C9.90378 0.17609 10.067 7.05914e-05 10.2684 0C10.4694 7.05914e-05 10.6328 0.176055 10.6328 0.39383L10.6328 2.65018C10.6328 2.80329 10.5085 2.9275 10.3554 2.92753L1.27627 2.92757C1.12316 2.92753 0.99888 2.80329 0.99888 2.65018Z"
      fill={color}
    />
    <path
      d="M5.2883 17.4118V2.43091H6.34375V17.4118C6.34375 17.7032 6.10745 17.9395 5.81601 17.9395C5.52461 17.9395 5.2883 17.7032 5.2883 17.4118Z"
      fill={color}
    />
  </svg>
)

HomeAndGarden.propTypes = {
  color: string
}

export default HomeAndGarden
