import React from 'react'
import { string } from 'prop-types'

const Electrolytes = ({ color, ...rest }) => (
  <svg viewBox="0 0 13 28" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 20.5262C13 24.1121 10.094 27.0262 6.5 27.0262C2.91407 27.0262 0 24.1202 0 20.5262C0 17.8225 3.41594 11.9215 6.58095 11.9215C9.79452 11.9215 12.9919 17.863 12.9919 20.5262H13ZM7.7142 2.31507C7.7142 2.31507 7.13138 2.96264 6.61333 2.96264C6.09527 2.96264 5.55293 2.50125 5.4396 2.22603C5.32628 1.95081 6.18431 3.10025 7.7142 2.31507ZM5.64197 8.27584C5.64197 8.27584 5.97385 9.68431 7.7061 10.3804C9.43836 11.0847 10.3854 9.58717 10.3854 9.58717C10.3854 9.58717 7.48755 11.1333 5.64197 8.26775V8.27584ZM10.7092 18.8587C10.7092 18.8587 11.3244 21.7565 10.005 23.6021C8.68555 25.4477 6.92902 25.5367 6.92902 25.5367C6.92902 25.5367 10.3531 22.995 10.7011 18.8667L10.7092 18.8587ZM6.60523 0C7.43898 0 8.11893 1.2142 8.11893 1.97509C8.11893 2.73599 7.43898 3.35118 6.60523 3.35118C5.77148 3.35118 5.09153 2.73599 5.09153 1.97509C5.09153 1.2142 5.77148 0 6.60523 0ZM6.05479 6.05791C5.32628 6.05791 4.73537 6.64882 4.73537 7.37733C4.73537 8.02491 5.07534 11.3275 8.59651 11.2385C10.005 11.2061 11.1463 10.0971 11.1463 8.68867C11.1463 7.2802 10.005 6.13885 8.59651 6.13885C7.46326 6.13885 6.78331 6.04981 6.0467 6.04981L6.05479 6.05791Z"
      fill={color}
    />
  </svg>
)

Electrolytes.propTypes = {
  color: string
}

export default Electrolytes
