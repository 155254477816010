import React from 'react'
import { string } from 'prop-types'

const FinalGrade = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 20" fill="none" {...rest}>
    <path
      d="M8.1612 13.0175L8.23997 10.3391C8.23997 10.1185 8.12969 9.89796 7.92487 9.77192L7.81458 9.7089L7.1371 9.29926C7.31041 8.99991 7.20012 8.63753 6.90077 8.47998L6.86926 8.46422C6.3966 8.22789 5.84516 8.44847 5.64035 8.92113C4.97862 10.4336 4.37992 11.9777 3.84424 13.5374C3.74971 13.5532 3.65517 13.569 3.54489 13.5847C3.21403 13.6477 3.02496 13.9786 3.10374 14.2937C3.151 14.4828 3.2928 14.6088 3.45035 14.6718C3.34007 15.0184 3.22978 15.3651 3.11949 15.7117C3.04072 15.9795 3.16676 16.2789 3.41884 16.3892C3.70244 16.5152 4.0333 16.3576 4.14359 16.074C4.34841 15.5069 4.56898 14.9397 4.80531 14.3725C5.5143 14.1677 6.22329 13.9786 6.94804 13.821L6.9953 15.4596C7.01106 15.7432 7.23163 15.9953 7.51523 15.9953C7.81458 15.9953 8.06667 15.7747 8.06667 15.4753L8.11393 13.569C8.12969 13.569 8.14544 13.569 8.1612 13.5532C8.31875 13.5217 8.41328 13.3799 8.39753 13.2381C8.39753 13.1278 8.28724 13.0333 8.1612 13.0175ZM5.26222 13.3169C5.56157 12.5921 5.89243 11.8674 6.22329 11.1584C6.38085 10.8275 6.86926 10.9536 6.88502 11.3159L6.93228 13.112C6.38085 13.1751 5.82941 13.2381 5.26222 13.3169Z"
      fill={color}
    />
    <path
      d="M12.1015 10.6386C12.1015 10.3708 11.8494 10.1502 11.5815 10.1817C11.3137 10.2132 11.1246 10.4496 11.1719 10.7174C11.2349 11.127 11.2822 11.5367 11.3295 11.9463C10.841 11.9306 10.3526 11.9306 9.86421 11.9463C9.59637 11.9463 9.37579 12.1984 9.4073 12.4662C9.43881 12.7341 9.67514 12.9231 9.94298 12.8759C10.4314 12.7971 10.9041 12.7498 11.3925 12.7026C11.4397 13.2698 11.4713 13.837 11.487 14.4199C11.487 14.5459 11.5973 14.6562 11.7233 14.6562C11.8494 14.6562 11.9597 14.5617 11.9754 14.4357C12.0384 13.837 12.07 13.2383 12.1015 12.6395C12.6214 12.5923 13.1413 12.5765 13.6612 12.5608C13.7873 12.5608 13.8976 12.4505 13.8976 12.3244C13.8976 12.1984 13.803 12.0881 13.677 12.0724C13.1571 12.0251 12.6371 11.9936 12.1172 11.9621C12.1015 11.5367 12.1015 11.0798 12.1015 10.6386Z"
      fill={color}
    />
    <path
      d="M16.7006 4.95085L15.6766 3.92675L13.5496 1.76827L12.431 0.633888C12.3049 0.507846 12.1316 0.444824 11.9583 0.444824H5.05746H1.92215C0.866543 0.444824 0 1.31137 0 2.36697V5.50228V11.7729V18.0435C0 19.0991 0.866543 19.9657 1.92215 19.9657H6.93234H10.0677H15.0778C16.1335 19.9657 17 19.0991 17 18.0435V11.7571V5.50228C17 5.26595 16.8897 5.06114 16.7006 4.95085ZM15.6766 11.7571V18.0278C15.6766 18.3586 15.4087 18.6265 15.0778 18.6265H10.0677H6.9481H1.92215C1.59129 18.6265 1.32345 18.3586 1.32345 18.0278V11.7571V3.911V2.36697C1.32345 2.03611 1.59129 1.76827 1.92215 1.76827H3.46617H11.2966V4.24186C11.2966 5.29746 12.1631 6.16401 13.2187 6.16401H15.6923V11.7571H15.6766Z"
      fill={color}
    />
  </svg>
)

FinalGrade.propTypes = {
  color: string
}

export default FinalGrade
