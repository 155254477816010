import React from 'react'
import { string } from 'prop-types'

const Cardiovascular = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 22" fill="none" {...rest}>
    <path
      d="M16.7424 16.9318C16.0532 14.6025 14.8433 10.9244 14.8433 10.9244C14.9346 9.06479 14.3703 7.42316 12.4573 6.55472C13.1291 6.03461 14.4548 5.68577 14.4548 5.68577L14.712 3.43585C14.712 3.43585 13.7743 3.12443 11.6738 3.28381C11.5708 2.61189 11.2598 2.09789 10.7479 1.75529C9.63266 1.00909 7.8901 1.30143 7.24287 1.44673V0H4.90419V2.3881C4.88001 2.41021 4.85639 2.43248 4.83305 2.45486L4.20141 1.49487L2.03092 1.62255L1.3765 8.05374C0.133587 9.3812 -0.278811 10.9858 0.184976 12.7019C0.694485 14.5871 2.12562 16.7491 3.9199 18.3441C5.49504 19.7442 8.11086 21.4132 11.6276 21.4132C13.0675 21.4132 14.5429 21.1269 16.0128 20.5623L16.026 20.5567C16.0902 20.5266 17.5868 19.7852 16.7424 16.9318ZM14.4463 10.9834C13.8724 10.9154 12.4934 10.763 12.0153 9.25531C11.7141 8.30513 11.6635 7.44415 12.1489 6.85127C12.6679 7.05537 14.6271 8.02619 14.4463 10.9834ZM5.29689 2.56505C5.30767 2.44892 5.29689 0.392707 5.29689 0.392707H6.85022L6.85033 1.95714C6.85033 1.95714 9.32045 1.27181 10.5302 2.08213C10.9438 2.35916 11.1973 2.78025 11.2847 3.33481C10.6496 3.43181 10.0471 3.59405 9.48348 3.8212C8.17386 4.349 7.11255 5.21509 6.32747 6.39612L3.81112 6.44639C3.78161 5.88807 3.90514 3.58491 5.29689 2.56505Z"
      fill={color}
    />
  </svg>
)

Cardiovascular.propTypes = {
  color: string
}

export default Cardiovascular
