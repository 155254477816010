import React from 'react'
import { string } from 'prop-types'

const IpSubnet = ({ color, ...rest }) => (
  <svg viewBox="0 0 21 21" fill="none" {...rest}>
    <path
      d="M12.4688 3.9375H11.8125V5.25H12.4688C12.8297 5.25 13.125 4.956 13.125 4.59375C13.125 4.2315 12.8297 3.9375 12.4688 3.9375Z"
      fill={color}
    />
    <path
      d="M15.75 9.84375V1.96875C15.75 0.885938 14.8641 0 13.7812 0H7.21875C6.13594 0 5.25 0.885938 5.25 1.96875V9.84375C5.25 10.9266 6.13594 11.8125 7.21875 11.8125H9.1875V14.4375H11.8125V11.8125H13.7812C14.8641 11.8125 15.75 10.9266 15.75 9.84375ZM9.1875 8.53125C9.1875 8.8935 8.8935 9.1875 8.53125 9.1875C8.169 9.1875 7.875 8.8935 7.875 8.53125V3.28125C7.875 2.919 8.169 2.625 8.53125 2.625C8.8935 2.625 9.1875 2.919 9.1875 3.28125V8.53125ZM12.4688 6.5625H11.8125V8.53125C11.8125 8.8935 11.5185 9.1875 11.1562 9.1875C10.794 9.1875 10.5 8.8935 10.5 8.53125V3.28125C10.5 2.919 10.794 2.625 11.1562 2.625H12.4688C13.5542 2.625 14.4375 3.50831 14.4375 4.59375C14.4375 5.67919 13.5542 6.5625 12.4688 6.5625Z"
      fill={color}
    />
    <path d="M6.5625 17.0625H0V19.6875H6.5625V17.0625Z" fill={color} />
    <path d="M21 17.0625H14.4375V19.6875H21V17.0625Z" fill={color} />
    <path d="M13.125 15.75H7.875V21H13.125V15.75Z" fill={color} />
  </svg>
)

IpSubnet.propTypes = {
  color: string
}

export default IpSubnet
