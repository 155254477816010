import React from 'react'
import { string } from 'prop-types'

const Password = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 17" fill="none" {...rest}>
    <path
      d="M13.0635 2.31491C13.0545 1.79591 12.6775 1.37891 12.2105 1.37891C11.7435 1.37891 11.3605 1.79591 11.3535 2.31491V3.00991H13.0635V2.31491Z"
      fill={color}
    />
    <path
      d="M23.397 0H0.603C0.27 0 0 0.3 0 0.67V15.486C0 15.855 0.27 16.156 0.603 16.156H23.398C23.731 16.156 24.001 15.855 24.001 15.486V0.67C24 0.3 23.73 0 23.397 0ZM10.464 3.132C10.464 3.065 10.538 3.011 10.629 3.011H10.806V2.33C10.806 2.325 10.807 2.32 10.807 2.315C10.814 1.458 11.439 0.765 12.21 0.765C12.981 0.765 13.606 1.458 13.613 2.315V3.01H13.792C13.882 3.01 13.956 3.064 13.956 3.131V5.468C13.956 5.534 13.882 5.59 13.792 5.59H10.629C10.538 5.59 10.464 5.535 10.464 5.468V3.132ZM22.959 15.257H1.105V6.198H22.959V15.257Z"
      fill={color}
    />
    <path
      d="M6.87173 10.601L5.78373 11.918L6.43673 12.307L7.17073 10.785H7.19173L7.89173 12.296L8.61273 11.907L7.51373 10.613V10.58L9.24273 10.785V10.052L7.51373 10.267V10.246L8.60273 8.89702L7.91473 8.52002L7.21573 10.041H7.19173L6.48273 8.53102L5.78373 8.90802L6.86073 10.234V10.256L5.17773 10.052V10.785L6.87173 10.569V10.601Z"
      fill={color}
    />
    <path
      d="M11.6276 10.601L10.5386 11.918L11.1916 12.307L11.9246 10.785H11.9476L12.6466 12.296L13.3686 11.907L12.2686 10.613V10.58L13.9976 10.785V10.052L12.2686 10.267V10.246L13.3556 8.89702L12.6696 8.52002L11.9706 10.041H11.9476L11.2366 8.53102L10.5386 8.90802L11.6146 10.234V10.256L9.93164 10.052V10.785L11.6276 10.569V10.601Z"
      fill={color}
    />
    <path
      d="M16.3805 10.601L15.2925 11.918L15.9455 12.307L16.6785 10.785H16.7015L17.3995 12.296L18.1215 11.907L17.0215 10.613V10.58L18.7515 10.785V10.052L17.0215 10.267V10.246L18.1095 8.89702L17.4235 8.52002L16.7245 10.041H16.7015L15.9915 8.53102L15.2925 8.90802L16.3685 10.234V10.256L14.6855 10.052V10.785L16.3805 10.569V10.601Z"
      fill={color}
    />
  </svg>
)

Password.propTypes = {
  color: string
}

export default Password
