import React from 'react'
import { string, object, node } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

const StyledCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  /* align-items: center; */
  width: 100%;

  ${media.greaterThan('medium')`
    align-items: unset;
    width: unset;
  `}
`

const Col = ({ children, style, className }) => (
  <StyledCol className={className} style={style}>
    {children}
  </StyledCol>
)

Col.propTypes = {
  style: object,
  className: string,
  children: node
}

export default Col
