import React from 'react'
import { string } from 'prop-types'

const ConstructionMaterials = ({ color, ...rest }) => (
  <svg
    width="26"
    height="12"
    viewBox="0 0 26 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M25.523 0.284634L23.4199 0.109619C23.2236 0.109619 23.0554 0.227769 22.9817 0.397186H22.3117L20.0392 2.84268L18.0274 0.873614C18.0159 0.824409 17.9976 0.776678 17.9711 0.732188C17.8821 0.583691 17.7216 0.492648 17.5483 0.492648L1.29552 0.492353C1.0825 0.492353 0.893341 0.62936 0.826753 0.831776C0.760165 1.03419 0.831172 1.25635 1.00295 1.38275L2.81821 2.66383L2.84266 4.84886C1.24543 5.01445 0 6.36448 0 8.00532C0 9.75811 1.42074 11.1792 3.17354 11.1792C4.9119 11.1792 6.32292 9.7808 6.3462 8.04804L10.3123 7.71952C10.4617 7.96466 10.896 8.66766 12.1874 10.7617C12.6565 11.5221 13.3583 11.9585 14.1131 11.9585H14.1134C15.208 11.9585 15.9529 11.0713 16.1945 9.84179C16.4971 8.30319 16.6641 7.53566 16.7431 7.18652L22.6067 0.889819H23.0495C23.137 0.996772 23.2702 1.06483 23.419 1.06483L25.5221 0.889819H25.6647V0.876266C25.8589 0.837669 26 0.722759 26 0.587226C26.0009 0.420167 25.7867 0.284634 25.523 0.284634ZM3.17295 9.75959C2.20418 9.75959 1.41897 8.97408 1.41897 8.00532C1.41897 7.03655 2.20418 6.25104 3.17295 6.25104C4.14172 6.25104 4.92722 7.03655 4.92722 8.00532C4.92722 8.97408 4.14201 9.75959 3.17295 9.75959ZM6.31467 7.55747C6.11196 6.1214 4.94814 4.99383 3.4947 4.84769L3.46052 3.11669L9.25486 7.20596C9.32233 7.25576 9.401 7.28611 9.4832 7.29701L6.31467 7.55747ZM15.6821 9.78817C15.4567 10.7416 14.9349 11.4429 14.1137 11.4429C13.5418 11.4429 12.9997 11.0958 12.6267 10.4909C11.4095 8.5171 11.129 8.18976 10.8704 7.69948L14.1237 7.40367L16.2734 7.22571C16.2448 7.71952 16.061 8.18416 15.6821 9.78817ZM16.4806 6.67179L16.477 6.67208C16.4808 6.66943 16.4844 6.66589 16.4885 6.66324L16.4806 6.67179ZM16.6146 6.528C16.6208 6.51828 16.6273 6.50885 16.6329 6.49824L17.8983 1.54981L19.6485 3.26254L16.6146 6.528Z"
      fill={color}
    />
  </svg>
)

ConstructionMaterials.propTypes = {
  color: string
}

export default ConstructionMaterials
