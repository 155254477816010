import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: inline-block;

  &:hover .tooltiptext {
    visibility: visible;
  }
`

const TooltipContent = styled.div`
  visibility: hidden;
  width: fit-content;
  bottom: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 5;

  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  &:hover {
    visibility: visible;
  }
`

const Tooltip = ({ content = 'Hover over me', tooltipText = 'Tooltip text' }) => (
  <Container>
    {content}
    <TooltipContent className="tooltiptext">{tooltipText}</TooltipContent>
  </Container>
)

Tooltip.propTypes = {
  content: string,
  tooltipText: string
}

export default Tooltip
