import React from 'react'
import { number, string, object, oneOf } from 'prop-types'

import Addiction from './icons/Addiction'
import Algebra from './icons/Algebra'
import Arithmetic from './icons/Arithmetic'
import ArrowDown from './icons/ArrowDown'
import Bandwidth from './icons/Bandwidth'
import BodyMeasurement from './icons/BodyMeasurements'
import BusinessPlanning from './icons/BusinessPlanning'
import Cardiovascular from './icons/Cardiovascular'
import CollegeGpa from './icons/CollegeGpa'
import Coordinate from './icons/Coordinate'
import DebtManagement from './icons/DebtManagement'
import DebtManagementDay from './icons/DebtManagementDay'
import DiceRoller from './icons/DiceRoller'
import Dietary from './icons/Dietary'
import Electrolytes from './icons/Electrolytes'
import EveryDay from './icons/EveryDay'
import FertilityPregnancy from './icons/FertilityPregnancy'
import FinalGrade from './icons/FinalGrade'
import GeneralHealth from './icons/GeneralHealth'
import Geometry2D from './icons/Geometry2D'
import Geometry3D from './icons/Geometry3D'
import Gpa from './icons/Gpa'
import Hematology from './icons/Hematology'
import HighSchoolGpa from './icons/HighSchoolGpa'
import Investment from './icons/Investiment'
import IpSubnet from './icons/IpSubnet'
import Macroeconomics from './icons/Macroeconomics'
import MetabolicDisorders from './icons/MetabolicDisorders'
import Microeconomics from './icons/Microeconomics'
import MicroMacroEconomicsDays from './icons/MicroMacroEconomicsDay'
import NotePad from './icons/NotePad'
import OnlineClock from './icons/OnlineClock'
import Other from './icons/Other'
import Password from './icons/Password'
import Pediatric from './icons/Pediatric'
import Percentages from './icons/Percentages'
import Percentile from './icons/Percentile'
import PersonalFinance from './icons/PersonalFinance'
import PersonalFinanceDay from './icons/PersonalFinanceDay'
import Pulmonary from './icons/Pulmonary'
import RealState from './icons/RealState'
import RealStateDay from './icons/RealStateDay'
import Reload from './icons/Reload'
import Sales from './icons/Sales'
import SalesDay from './icons/SalesDay'
import Search from './icons/Search'
import Sequences from './icons/Sequences'
import Tax from './icons/Tax'
import Test from './icons/Test'
import Transportation from './icons/Transportation'
import Trigonometry from './icons/Trigonometry'
import VideoDownloader from './icons/VideoDownloader'
import More from './icons/More'
import ConstructionConverter from './icons/ConstructionConverter'
import ConstructionMaterials from './icons/ConstructionMaterials'
import Driveway from './icons/Driveway'
import HomeAndGarden from './icons/HomeAndGarden'
import Roofing from './icons/Roofing'
import TaxDay from './icons/TaxDay'
import Heart from './icons/Heart'

import { black } from '../colors'

const ICONS = {
  addiction: Addiction,
  algebra: Algebra,
  arithmetic: Arithmetic,
  'arrow-down': ArrowDown,
  bandwidth: Bandwidth,
  'body-measurement': BodyMeasurement,
  'business-planning': BusinessPlanning,
  cardiovascular: Cardiovascular,
  'college-gpa': CollegeGpa,
  coordinate: Coordinate,
  'debt-management': DebtManagement,
  'debt-managementDay': DebtManagementDay,
  'dice-roller': DiceRoller,
  dietary: Dietary,
  electrolytes: Electrolytes,
  'every-day': EveryDay,
  'fertility-pregnancy': FertilityPregnancy,
  'final-grade': FinalGrade,
  'general-health': GeneralHealth,
  'geometry-2d': Geometry2D,
  'geometry-3d': Geometry3D,
  gpa: Gpa,
  hematology: Hematology,
  'high-school-gpa': HighSchoolGpa,
  investment: Investment,
  'ip-subnet': IpSubnet,
  macroeconomics: Macroeconomics,
  'metabolic-disorders': MetabolicDisorders,
  microeconomics: Microeconomics,
  'micro-macro-economics-days': MicroMacroEconomicsDays,
  'note-pad': NotePad,
  'online-clock': OnlineClock,
  other: Other,
  password: Password,
  pediatric: Pediatric,
  percentages: Percentages,
  percentile: Percentile,
  'personal-finance': PersonalFinance,
  'personal-financeDay': PersonalFinanceDay,
  pulmonary: Pulmonary,
  'real-state': RealState,
  'real-state-day': RealStateDay,
  reload: Reload,
  sales: Sales,
  'sales-day': SalesDay,
  search: Search,
  sequences: Sequences,
  tax: Tax,
  test: Test,
  transportation: Transportation,
  trigonometry: Trigonometry,
  'video-downloader': VideoDownloader,
  more: More,
  'construction-converter': ConstructionConverter,
  'construction-materials': ConstructionMaterials,
  driveway: Driveway,
  'home-and-garden': HomeAndGarden,
  roofing: Roofing,
  'tax-day': TaxDay,
  heart: Heart
}

const Icon = ({ name, size = 14, color = black, className, style }) => {
  const IconComponent = ICONS[name]

  return (
    <IconComponent width={size} height={size} color={color} className={className} style={style} />
  )
}

Icon.propTypes = {
  name: oneOf(Object.keys(ICONS)),
  size: number,
  color: string,
  className: string,
  style: object
}

export default Icon
