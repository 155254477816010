import React from 'react'
import { string } from 'prop-types'

const NotePad = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M18.7224 8.75C18.3992 8.75 18.1371 9.01216 18.1371 9.33532L18.137 21.8472H2.15306V5.86302H14.6648C14.988 5.86302 15.2501 5.60092 15.2501 5.2777C15.2501 4.95443 14.988 4.69238 14.6648 4.69238H1.56774C1.24447 4.69238 0.982422 4.95443 0.982422 5.2777V22.4324C0.982422 22.7556 1.24447 23.0177 1.56774 23.0177H18.7224C19.0455 23.0177 19.3077 22.7556 19.3077 22.4324L19.3078 9.3352C19.3077 9.01204 19.0457 8.75 18.7224 8.75Z"
      fill={color}
      stroke="#0050AA"
      strokeWidth="0.5"
    />
    <path
      d="M23.8288 1.9521L22.0481 0.17144C21.9383 0.0616928 21.7895 0 21.6342 0C21.479 0 21.3301 0.0616928 21.2204 0.17144L19.9036 1.48812C19.9036 1.48823 19.9035 1.48829 19.9033 1.48841L13.0322 8.35948H4.1947C3.87142 8.35948 3.60938 8.62159 3.60938 8.9448C3.60938 9.26807 3.87142 9.53012 4.1947 9.53012H11.8616L10.0583 11.3335C10.0543 11.3374 10.0508 11.3419 10.047 11.346C10.0386 11.3547 10.0304 11.3636 10.0226 11.3729C10.0178 11.3787 10.0133 11.3849 10.0087 11.391C10.0017 11.4 9.99469 11.4092 9.98813 11.4187C9.9831 11.4261 9.97865 11.4339 9.97397 11.4415C9.96864 11.4501 9.9632 11.4586 9.95834 11.4675C9.95307 11.4771 9.94857 11.4871 9.94383 11.497C9.94072 11.5035 9.93727 11.5097 9.93429 11.5164L9.8572 11.6959H4.1947C3.87142 11.6959 3.60938 11.958 3.60938 12.2812C3.60938 12.6044 3.87142 12.8665 4.1947 12.8665H9.35435L8.59308 14.6383C8.53841 14.7656 8.53209 14.9046 8.56897 15.0321H4.19493C3.87166 15.0321 3.60961 15.2942 3.60961 15.6174C3.60961 15.9406 3.87166 16.2027 4.19493 16.2027H16.0958C16.4189 16.2027 16.6811 15.9406 16.6811 15.6174C16.6811 15.2942 16.4189 15.0321 16.0958 15.0321H10.235L12.4724 14.0708C12.4733 14.0704 12.4741 14.07 12.475 14.0696L12.4839 14.0658C12.4879 14.0641 12.4917 14.062 12.4956 14.0601C12.5082 14.0543 12.5207 14.0484 12.5329 14.0418C12.5397 14.0381 12.5461 14.034 12.5527 14.0302C12.5623 14.0243 12.572 14.0187 12.5813 14.0123C12.59 14.0064 12.5983 14 12.6066 13.9937C12.6134 13.9886 12.6202 13.9836 12.6268 13.9781C12.6367 13.9699 12.646 13.9612 12.6553 13.9524C12.6591 13.9488 12.6631 13.9458 12.6668 13.942L13.7422 12.8666H16.096C16.4192 12.8666 16.6813 12.6045 16.6813 12.2812C16.6813 11.9581 16.4192 11.6959 16.096 11.6959H14.9128L22.5118 4.09689C22.5119 4.09683 22.512 4.09671 22.5121 4.09654L23.8288 2.7798C24.0574 2.55129 24.0574 2.18067 23.8288 1.9521Z"
      fill={color}
    />
    <path
      d="M16.0955 19.5388C16.4187 19.5388 16.6809 19.2767 16.6809 18.9535C16.6809 18.6303 16.4187 18.3682 16.0955 18.3682H4.1947C3.87142 18.3682 3.60938 18.6303 3.60938 18.9535C3.60938 19.2767 3.87142 19.5388 4.1947 19.5388H16.0955Z"
      fill={color}
    />
  </svg>
)

NotePad.propTypes = {
  color: string
}

export default NotePad
