import React from 'react'
import { string } from 'prop-types'

const Hematology = ({ color, ...rest }) => (
  <svg viewBox="0 0 20 17" fill="none" {...rest}>
    <path
      d="M17.7544 9.70431C16.2963 9.64479 15.1059 10.9839 14.6 12.1742C14.1834 13.1563 13.9751 14.7632 14.8976 15.4774C16.8022 16.9951 19.2721 13.6919 19.3911 12.0255C19.5102 10.8649 19.0638 9.73407 17.7544 9.70431ZM18.2306 12.4718C18.171 13.186 17.4866 14.3466 16.6831 14.8227C17.3378 14.2871 17.8437 13.3646 17.9032 12.7397C17.9627 11.9957 17.6651 11.2517 16.8319 11.222C16.2963 11.1922 15.8201 11.46 15.4333 11.8469C15.8499 11.3113 16.4451 10.8649 17.1295 10.8946C17.9925 10.9542 18.2901 11.6981 18.2306 12.4718Z"
      fill={color}
    />
    <path
      d="M13.6167 0.0030691C11.5931 -0.0862059 9.92666 1.78857 9.21246 3.48479C9.00415 4.02044 8.79584 4.67513 8.73633 5.32981C8.97439 5.44885 9.1827 5.59764 9.36125 5.71667C10.373 6.40111 11.3551 7.35338 12.099 8.45444C14.0333 7.68072 15.7593 4.9132 15.9081 3.24673C16.0271 1.66954 15.4022 0.0923441 13.6167 0.0030691ZM14.2714 3.93117C14.2119 4.94295 13.2298 6.5499 12.099 7.23435C13.0215 6.49039 13.7357 5.18102 13.7952 4.31803C13.8845 3.27649 13.4679 2.23494 12.3073 2.20519C11.5634 2.17543 10.8789 2.56229 10.373 3.09794C10.9384 2.32422 11.8014 1.69929 12.7537 1.75881C13.9143 1.78857 14.3309 2.85987 14.2714 3.93117Z"
      fill={color}
    />
    <path
      d="M8.94524 6.34164C7.15974 5.15131 4.06487 4.05025 2.21985 5.62744C-1.55946 8.84134 4.03511 15.0311 7.33829 15.9536C9.59992 16.5785 12.0103 16.1619 12.6353 13.6027C13.3495 10.7459 11.1474 7.82956 8.94524 6.34164ZM2.93405 9.19844C2.87454 8.45448 3.1126 7.77004 3.76729 7.20463C5.01714 6.16309 7.07046 6.87729 8.2608 7.68077C8.35007 7.74028 8.43935 7.7998 8.52862 7.85932C8.43935 7.7998 8.37983 7.77004 8.29056 7.71053C7.15974 7.02608 5.10641 6.43092 3.8268 7.47246C3.23164 8.00811 2.96381 8.60328 2.93405 9.19844ZM10.7307 12.5611C10.3141 14.2574 8.70717 14.5549 7.1895 14.1383C6.26699 13.8705 5.07665 13.0075 4.1839 11.9065C5.01714 12.7992 6.08844 13.4836 6.95143 13.692C8.43935 14.0193 10.0463 13.6622 10.5522 12.1148C10.7307 11.5494 10.701 10.9839 10.5522 10.4483C10.82 11.103 10.9093 11.8469 10.7307 12.5611Z"
      fill={color}
    />
  </svg>
)

Hematology.propTypes = {
  color: string
}

export default Hematology
