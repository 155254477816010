import React from 'react'
import { number, string, oneOfType, func, element, object } from 'prop-types'
import styled from 'styled-components'

import { lightBlue, black, lightGrey } from '../colors'

import Label from '../Label'

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
`

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${lightBlue};
`

const StyledInput = styled.input`
  font-weight: bold;
  font-size: 16px;
  color: ${black};
  padding: 5px 10px;
  height: 35px;
  border: 0;
  outline: 0;
  flex: 1;
  width: 100%;

  :active,
  :focus {
    background-color: ${lightGrey};
  }

  &[type='number'] {
    font-size: 24px;
  }
`

const StyledAction = styled.div`
  display: flex;
  align-items: center;
`

const Input = ({
  value,
  onChange,
  label,
  action,
  className,
  styleLabel,
  styleWrapper,
  ...inputProps
}) => (
  <StyledInputContainer className={className}>
    {label && (
      <Label style={styleLabel} light>
        {label}
      </Label>
    )}
    <StyledInputWrapper style={styleWrapper}>
      <StyledInput
        {...inputProps}
        value={value}
        onChange={({ target }) => onChange(target.value)}
      />
      {action && <StyledAction>{action}</StyledAction>}
    </StyledInputWrapper>
  </StyledInputContainer>
)

Input.propTypes = {
  value: oneOfType([number, string]),
  onChange: func,
  label: string,
  className: string,
  action: element,
  styleLabel: object,
  styleWrapper: object
}

Input.defaultProps = {
  onChange: () => {}
}

export default Input
