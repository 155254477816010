import React from 'react'
import { string } from 'prop-types'

const Microeconomics = ({ color, ...rest }) => (
  <svg viewBox="0 0 17 17" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.06345 0C9.74361 0.0442708 10.4036 0.169034 11.0315 0.366241L9.06345 1.50118V0ZM11.7962 0.643939C12.2268 0.825047 12.6373 1.03835 13.0277 1.28788L9.06345 3.57789V2.22562L11.7962 0.647964V0.643939ZM13.5952 1.67827C13.9252 1.92779 14.2391 2.19744 14.5289 2.49124L9.05942 5.65057V4.2983L13.5911 1.68229L13.5952 1.67827ZM14.9635 2.96212C15.2171 3.25994 15.4545 3.57789 15.6678 3.91193L9.06345 7.72727V6.375L14.9676 2.96615L14.9635 2.96212ZM15.9818 4.44721C16.1669 4.7893 16.3319 5.14749 16.4688 5.52178L9.05942 9.79995V9.05942V8.44768L15.9818 4.45123V4.44721ZM16.67 6.12547C16.7827 6.51586 16.8672 6.91832 16.9235 7.33286L9.05942 11.8726V10.5204L16.67 6.12547ZM16.9879 8.01705C16.996 8.17401 17 8.33097 17 8.48793C17 8.7938 16.9839 9.09162 16.9517 9.38944L9.05942 13.9453V12.593L16.9879 8.01705ZM16.831 10.1823C16.7143 10.7538 16.5412 11.3092 16.3158 11.8324L9.0554 16.022V14.6697L16.8269 10.1823H16.831ZM15.7966 12.8546C14.4001 15.1849 11.925 16.7947 9.05942 16.9799V16.7424L15.7966 12.8506V12.8546ZM7.92045 16.9799C3.67448 16.6982 0.277699 13.3054 0 9.05942H7.92045V16.9799ZM0 7.92046C0.281723 3.67448 3.67448 0.277699 7.92045 0V7.92046H0Z"
      fill={color}
    />
  </svg>
)

Microeconomics.propTypes = {
  color: string
}

export default Microeconomics
