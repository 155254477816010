import React from 'react'
import { string } from 'prop-types'

const OnlineClock = ({ color, ...rest }) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.5832 6.91736H7.00021V10.5624H3.58295V6.91736H0V3.66579H3.58295V0H7.00021V3.66579H10.5832V6.91736Z"
      fill={color}
    />
  </svg>
)

OnlineClock.propTypes = {
  color: string
}

export default OnlineClock
