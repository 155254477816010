import React from 'react'
import { string, object, func, number } from 'prop-types'
import styled from 'styled-components'

import { orange } from '../colors'

const RangeContainer = styled.div``

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 15px;
`

const Text = styled.span`
  font-size: 11px;
  line-height: 10px;
`

const RangeValue = styled(Text)`
  font-weight: bold;
  display: flex;
`

const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: linear-gradient(
    to right,
    ${orange} 0%,
    ${orange} ${({ value, max }) => `${Math.round((value / max) * 100)}%`},
    #84c2e9 ${({ value, max }) => `${Math.round((value / max) * 100)}%`}
  );
  border-radius: 4px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  transition: box-shadow 0.2s ease-in-out;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${orange};
    cursor: pointer;
    position: relative;
  }

  ::-ms-fill-lower {
    background: ${orange};
  }

  ::-moz-range-thumb {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${orange};
    cursor: pointer;
    position: relative;
  }
`

const Img = styled.img`
  margin-right: 15px;
`

const Value = styled.div`
  width: 17px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const RangeSlider = ({ label, max, symbol, style, className, img, onChangeValue, value }) => (
  <RangeContainer style={style} className={className}>
    <Label>
      <Text>{label}</Text>{' '}
      <RangeValue>
        {img && <Img src={img} alt="Smile" />}
        <Value>
          {value}
          {symbol}
        </Value>
      </RangeValue>
    </Label>
    <Slider
      onChange={({ target: { value } }) => {
        if (onChangeValue) {
          onChangeValue(value)
        }
      }}
      type="range"
      min="0"
      max={max}
      value={value}
    />
  </RangeContainer>
)

RangeSlider.propTypes = {
  label: string,
  className: string,
  value: number,
  symbol: string,
  max: string,
  style: object,
  img: string,
  onChangeValue: func
}

export default RangeSlider
