import React from 'react'
import { string } from 'prop-types'

const Percentile = ({ color, ...rest }) => (
  <svg viewBox="0 0 18 17" fill="none" {...rest}>
    <path
      d="M13.2871 16.7166H17.7061C15.5956 15.2404 14.0006 13.1829 13.1319 10.8159L11.6936 6.89712C10.8508 4.6007 7.47413 4.6007 6.63159 6.89712L5.08614 11.1086C4.28454 13.2925 2.86311 15.2169 0.982703 16.6639L0.914062 16.7166H5.33301H13.2871ZM9.16276 16.7166H13.2871H9.16276ZM5.33301 16.7166H9.16276H5.33301Z"
      fill={color}
    />
    <path
      d="M13.5811 16.4333V16.15H12.9919V16.4333H9.45671V16.15H8.86751V16.4333H5.62695V16.15H5.03776V16.4333H0.913411V0H0.324219V16.7167C0.324219 16.8731 0.455903 17 0.618815 17H18V16.4333H13.5811Z"
      fill={color}
    />
    <path d="M9.45638 7.0835H8.86719V7.65016H9.45638V7.0835Z" fill={color} />
    <path d="M9.45638 8.2168H8.86719V8.78346H9.45638V8.2168Z" fill={color} />
    <path d="M9.45638 9.3501H8.86719V9.91676H9.45638V9.3501Z" fill={color} />
    <path d="M9.45638 10.4834H8.86719V11.0501H9.45638V10.4834Z" fill={color} />
    <path d="M9.45638 11.6167H8.86719V12.1834H9.45638V11.6167Z" fill={color} />
    <path d="M9.45638 12.75H8.86719V13.3167H9.45638V12.75Z" fill={color} />
    <path d="M9.45638 13.8833H8.86719V14.45H9.45638V13.8833Z" fill={color} />
    <path d="M9.45638 15.0166H8.86719V15.5833H9.45638V15.0166Z" fill={color} />
    <path d="M5.6263 12.75H5.03711V13.3167H5.6263V12.75Z" fill={color} />
    <path d="M5.6263 13.8833H5.03711V14.45H5.6263V13.8833Z" fill={color} />
    <path d="M5.6263 15.0166H5.03711V15.5833H5.6263V15.0166Z" fill={color} />
    <path d="M9.45638 5.94995H8.86719V6.51662H9.45638V5.94995Z" fill={color} />
    <path d="M13.5814 12.75H12.9922V13.3167H13.5814V12.75Z" fill={color} />
    <path d="M13.5814 13.8833H12.9922V14.45H13.5814V13.8833Z" fill={color} />
    <path d="M13.5814 15.0166H12.9922V15.5833H13.5814V15.0166Z" fill={color} />
  </svg>
)

Percentile.propTypes = {
  color: string
}

export default Percentile
