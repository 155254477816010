import React from 'react'
import { string } from 'prop-types'

const RealStateDay = ({ color, ...rest }) => (
  <svg viewBox="0 0 16 12" fill="none" {...rest}>
    <path
      d="M15.6864 10.7872H15.3568V0.8032H8.5952V10.784H8.2656C8.1536 10.784 8.0544 10.8416 8 10.9312C7.9456 10.8448 7.8464 10.784 7.7344 10.784H7.4048V0.8032H5.8816V0.2944C5.8816 0.1312 5.7504 0 5.5872 0H2.4608C2.2976 0 2.1664 0.1312 2.1664 0.2944V0.8032H0.6432V10.784H0.3136C0.1408 10.784 0 10.9248 0 11.0976V11.6576H7.952H8.0512H16V11.0976C16 10.928 15.8592 10.7872 15.6864 10.7872ZM3.296 10.56H1.9328V9.9104H3.296V10.56ZM3.296 9.1328H1.9328V8.4832H3.296V9.1328ZM3.296 7.7088H1.9328V7.0592H3.296V7.7088ZM3.296 6.2816H1.9328V5.632H3.296V6.2816ZM3.296 4.8544H1.9328V4.2048H3.296V4.8544ZM3.296 3.4272H1.9328V2.7776H3.296V3.4272ZM3.296 2.0032H1.9328V1.3504H3.296V2.0032ZM6.1184 10.56H4.7552V9.9104H6.1184V10.56ZM6.1184 9.1328H4.7552V8.4832H6.1184V9.1328ZM6.1184 7.7088H4.7552V7.0592H6.1184V7.7088ZM6.1184 6.2816H4.7552V5.632H6.1184V6.2816ZM6.1184 4.8544H4.7552V4.2048H6.1184V4.8544ZM6.1184 3.4272H4.7552V2.7776H6.1184V3.4272ZM6.1184 2.0032H4.7552V1.3504H6.1184V2.0032ZM11.248 10.56H9.8848V9.9104H11.248V10.56ZM11.248 9.1328H9.8848V8.4832H11.248V9.1328ZM11.248 7.7088H9.8848V7.0592H11.248V7.7088ZM11.248 6.2816H9.8848V5.632H11.248V6.2816ZM11.248 4.8544H9.8848V4.2048H11.248V4.8544ZM11.248 3.4272H9.8848V2.7776H11.248V3.4272ZM11.248 2.0032H9.8848V1.3504H11.248V2.0032ZM14.0672 10.56H12.704V9.9104H14.0672V10.56ZM14.0672 9.1328H12.704V8.4832H14.0672V9.1328ZM14.0672 7.7088H12.704V7.0592H14.0672V7.7088ZM14.0672 6.2816H12.704V5.632H14.0672V6.2816ZM14.0672 4.8544H12.704V4.2048H14.0672V4.8544ZM14.0672 3.4272H12.704V2.7776H14.0672V3.4272ZM14.0672 2.0032H12.704V1.3504H14.0672V2.0032Z"
      fill={color}
    />
  </svg>
)

RealStateDay.propTypes = {
  color: string
}

export default RealStateDay
