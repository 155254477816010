import React from 'react'
import { string } from 'prop-types'

const Geometry2D = ({ color, ...rest }) => (
  <svg viewBox="0 0 19 17" {...rest}>
    <path
      d="M18.5979 16.1025H0.907347C0.746523 16.1025 0.666111 16.022 0.585699 15.9416C0.505288 15.7808 0.505288 15.7004 0.585699 15.5396L9.431 0.180927C9.59182 -0.0603088 9.99388 -0.0603088 10.0743 0.180927L18.9196 15.4592C19 15.5396 19 15.62 19 15.7808C19 15.9416 18.8392 16.1025 18.5979 16.1025Z"
      fill={color}
    />
  </svg>
)

Geometry2D.propTypes = {
  color: string
}

export default Geometry2D
