import React, { useState, useEffect } from 'react'
import { string, func, bool, object } from 'prop-types'
import styled, { css } from 'styled-components'

import Label from '../Label'
import { grey, blue, white } from '../colors'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const StyledLabel = styled(Label)`
  margin-left: 5px;
`

const StyledToggle = styled.div`
  width: 40px;
  height: 20px;
  border: 1px solid ${grey};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.25s ease-in;
  background-color: ${white};

  :before {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    left: 1px;
    top: 1px;
    border-radius: 100%;
    background-color: ${grey};
    animation: ease-in;
    transition: 0.25s;
  }

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${blue};
      background-color: ${blue};

      :before {
        left: 20px;
        background-color: ${white};
      }
    `}
`

const Toggle = ({ value, checked = false, label, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked)
    }
  }, [checked, isChecked])

  const handleChangeToggl = () => {
    setIsChecked(!isChecked)
    onChange(!isChecked, value)
  }

  return (
    <Container onClick={handleChangeToggl}>
      <StyledToggle checked={isChecked} />
      {label && <StyledLabel light>{label}</StyledLabel>}
    </Container>
  )
}

Toggle.propTypes = {
  value: object,
  checked: bool,
  label: string,
  onChange: func
}

export default Toggle
