import React from 'react'
import { string } from 'prop-types'

const Pediatric = ({ color, ...rest }) => (
  <svg viewBox="0 0 21 15" fill="none" {...rest}>
    <path
      d="M17.3461 6.2548C17.8678 6.78706 18.3269 7.26308 18.7944 7.73017C19.1622 8.09802 19.5317 8.13574 19.8255 7.85125C20.1172 7.56907 20.0881 7.18105 19.7315 6.82113C18.9316 6.01398 18.1225 5.21576 17.3246 4.40695C17.0229 4.10096 16.6759 3.93788 16.2419 3.9435C15.579 3.9521 14.916 3.95144 14.2531 3.9435C13.8218 3.93821 13.4668 4.08674 13.1631 4.39306C12.4013 5.16151 11.6299 5.92069 10.8664 6.68782C10.397 7.15954 10.3245 7.52739 10.6266 7.83768C10.9468 8.16683 11.3163 8.09174 11.8016 7.60149C12.2333 7.1655 12.6666 6.73116 13.1522 6.24289C13.2108 7.02325 13.1952 7.68849 12.8998 8.34381C12.6143 8.9763 12.4049 9.64286 12.1479 10.3332C14.2372 10.3332 16.2465 10.3332 18.281 10.3332C17.97 8.98589 17.0295 7.81817 17.3461 6.2548Z"
      fill={color}
    />
    <path
      d="M17.087 14.111C17.0771 14.6393 16.7516 14.9903 16.2868 14.9962C15.8224 15.0028 15.476 14.6611 15.4674 14.1298C15.4562 13.418 15.4592 11.5195 15.4598 10.8076H17.0887C17.0894 11.0137 17.091 12.406 17.091 12.6118H17.0897C17.0894 13.1116 17.097 13.6115 17.087 14.111Z"
      fill={color}
    />
    <path
      d="M14.973 14.308C14.9651 14.705 14.5936 14.9934 14.2019 14.999C13.797 15.0047 13.4123 14.7046 13.4027 14.2921C13.3845 13.5263 13.3822 11.5736 13.3868 10.8071H14.9849C14.9876 11.5898 14.9886 13.5422 14.973 14.308Z"
      fill={color}
    />
    <path
      d="M16.7777 1.24141C16.7777 1.24141 17.0284 1.0641 17.2064 1.26292C17.4138 1.49481 17.3268 1.88714 17.3437 2.2047C17.3724 2.74821 17.4786 3.25632 18.0446 3.47399C18.6361 3.70125 19.0625 3.38169 19.4578 2.96919C18.8442 2.63938 18.8481 2.63905 18.8121 1.96653C18.7995 1.731 18.779 1.49282 18.731 1.26258C18.4872 0.0865889 17.7343 -0.253474 16.6956 0.339651L16.2434 0.620832L16.214 0.610577C16.2044 0.607269 16.1954 0.602638 16.1872 0.597345C15.9119 0.417389 15.5828 0.312525 15.2295 0.312525C14.8825 0.312525 14.5593 0.41342 14.2871 0.58709C14.2732 0.596022 14.2596 0.604953 14.2464 0.614216C13.6367 1.03367 13.4835 1.86795 13.8769 2.49482C13.9384 2.59306 14.0128 2.70223 14.0873 2.78989C14.5527 3.3377 15.4839 4.33473 16.6023 2.46207C16.8352 2.07238 16.991 1.64168 16.7777 1.24141C16.7763 1.2391 16.775 1.23645 16.7737 1.23414L16.7777 1.24141Z"
      fill={color}
    />
    <path
      d="M8.29666 7.89788C8.61324 8.20883 8.98175 8.21479 9.25466 7.94585C9.52955 7.67459 9.52294 7.31303 9.20603 6.99215C8.37374 6.14861 7.54839 5.29647 6.68103 4.48964C6.46005 4.28388 6.09981 4.15156 5.79316 4.1294C5.07764 4.0778 4.35451 4.08673 3.63766 4.12742C3.36508 4.14296 3.0366 4.24485 2.84474 4.42514C1.94231 5.27232 1.07231 6.15456 0.204947 7.03813C-0.0709412 7.31931 -0.0583708 7.68286 0.183114 7.93526C0.43783 8.20189 0.814943 8.21545 1.11035 7.93328C1.5341 7.52871 1.93636 7.10231 2.34953 6.68682C2.44645 6.58956 2.55032 6.49926 2.72796 6.33452C2.72796 6.81219 2.72796 7.17773 2.72796 7.54326C3.36376 7.52176 3.40313 7.48206 3.40478 6.90581C3.40577 6.61272 3.40048 6.31963 3.41107 6.0272C3.42198 5.72783 3.58804 5.55019 3.88676 5.54357C4.44581 5.53166 5.00519 5.53067 5.56425 5.5439C5.86064 5.55085 6.02968 5.72121 6.03465 6.02753C6.03961 6.33386 6.03795 6.64018 6.04126 6.94617C6.04655 7.4857 6.09882 7.53334 6.72205 7.534C6.72205 7.18368 6.72205 6.65705 6.72205 6.30541L8.29666 7.89788Z"
      fill={color}
    />
    <path
      d="M5.30879 10.4256C5.86718 10.4332 6.48512 10.4292 7.01407 10.4292L6.85595 8.5423C6.83577 8.09969 6.69849 7.85821 6.22974 7.81719C5.87479 7.78609 5.69384 7.55255 5.70708 7.18536C5.71171 7.05238 5.70807 6.91906 5.70807 6.78608C5.7084 5.87572 5.7084 5.87572 4.81061 5.87506C3.73517 5.87407 3.73517 5.87407 3.73286 6.93329C3.73154 7.65708 3.68423 7.70604 2.99451 7.88633C2.86682 7.91974 2.68191 8.03882 2.66503 8.14137C2.54595 8.87443 2.46358 9.61344 2.35938 10.4325C2.99947 10.4325 3.58565 10.4418 4.16621 10.4249C4.26644 10.4219 4.35609 10.3617 4.39777 10.2704L4.68689 9.63494L5.08649 10.2985C5.13347 10.3759 5.21749 10.4242 5.30879 10.4256Z"
      fill={color}
    />
    <path
      d="M6.61725 1.88093C6.61129 0.854459 5.75485 0.000662436 4.73003 8.35173e-07C3.69561 -0.000991567 2.81535 0.882577 2.82429 1.91236C2.83322 2.93222 3.69495 3.78535 4.72077 3.78998C5.7651 3.79428 6.62353 2.93023 6.61725 1.88093Z"
      fill={color}
    />
    <path
      d="M6.70955 14.0676C6.70029 14.6333 6.36486 15.0094 5.89281 14.9998C5.43002 14.9902 5.1098 14.627 5.10616 14.0812C5.10087 13.3065 5.10649 11.6597 5.10352 10.885H6.70955C6.70955 11.1768 6.7122 12.3415 6.7122 12.6333H6.71088C6.71154 13.1113 6.71749 13.5893 6.70955 14.0676Z"
      fill={color}
    />
    <path
      d="M4.33928 14.1273C4.32704 14.639 3.98929 14.9976 3.54304 14.9993C3.09646 15.0009 2.7435 14.641 2.73721 14.1359C2.72861 13.3433 2.73456 11.6777 2.73291 10.8848H4.34159C4.34159 11.1831 4.34391 12.3535 4.34391 12.6519H4.34159C4.34192 13.1441 4.35086 13.6357 4.33928 14.1273Z"
      fill={color}
    />
    <path
      d="M8.29666 7.89788C8.61324 8.20883 8.98175 8.21479 9.25466 7.94585C9.52955 7.67459 9.52294 7.31303 9.20603 6.99215C8.37374 6.14861 7.54839 5.29647 6.68103 4.48964C6.46005 4.28388 6.09981 4.15156 5.79316 4.1294C5.07764 4.0778 4.35451 4.08673 3.63766 4.12742C3.36508 4.14296 3.0366 4.24485 2.84474 4.42514C1.94231 5.27232 1.07231 6.15456 0.204947 7.03813C-0.0709412 7.31931 -0.0583708 7.68286 0.183114 7.93526C0.43783 8.20189 0.814943 8.21545 1.11035 7.93328C1.5341 7.52871 1.93636 7.10231 2.34953 6.68682C2.44645 6.58956 2.55032 6.49926 2.72796 6.33452C2.72796 6.81219 2.72796 7.17773 2.72796 7.54326C3.36376 7.52176 3.40313 7.48206 3.40478 6.90581C3.40577 6.61272 3.40048 6.31963 3.41107 6.0272C3.42198 5.72783 3.58804 5.55019 3.88676 5.54357C4.44581 5.53166 5.00519 5.53067 5.56425 5.5439C5.86064 5.55085 6.02968 5.72121 6.03465 6.02753C6.03961 6.33386 6.03795 6.64018 6.04126 6.94617C6.04655 7.4857 6.09882 7.53334 6.72205 7.534C6.72205 7.18368 6.72205 6.65705 6.72205 6.30541L8.29666 7.89788Z"
      fill={color}
    />
    <path
      d="M5.30879 10.4256C5.86718 10.4332 6.48512 10.4292 7.01407 10.4292L6.85595 8.5423C6.83577 8.09969 6.69849 7.85821 6.22974 7.81719C5.87479 7.78609 5.69384 7.55255 5.70708 7.18536C5.71171 7.05238 5.70807 6.91906 5.70807 6.78608C5.7084 5.87572 5.7084 5.87572 4.81061 5.87506C3.73517 5.87407 3.73517 5.87407 3.73286 6.93329C3.73154 7.65708 3.68423 7.70604 2.99451 7.88633C2.86682 7.91974 2.68191 8.03882 2.66503 8.14137C2.54595 8.87443 2.46358 9.61344 2.35938 10.4325C2.99947 10.4325 3.58565 10.4418 4.16621 10.4249C4.26644 10.4219 4.35609 10.3617 4.39777 10.2704L4.68689 9.63494L5.08649 10.2985C5.13347 10.3759 5.21749 10.4242 5.30879 10.4256Z"
      fill={color}
    />
    <path
      d="M6.61725 1.88093C6.61129 0.854459 5.75485 0.000662436 4.73003 8.35173e-07C3.69561 -0.000991567 2.81535 0.882577 2.82429 1.91236C2.83322 2.93222 3.69495 3.78535 4.72077 3.78998C5.7651 3.79428 6.62353 2.93023 6.61725 1.88093Z"
      fill={color}
    />
    <path
      d="M6.70955 14.0676C6.70029 14.6333 6.36486 15.0094 5.89281 14.9998C5.43002 14.9902 5.1098 14.627 5.10616 14.0812C5.10087 13.3065 5.10649 11.6597 5.10352 10.885H6.70955C6.70955 11.1768 6.7122 12.3415 6.7122 12.6333H6.71088C6.71154 13.1113 6.71749 13.5893 6.70955 14.0676Z"
      fill={color}
    />
    <path
      d="M4.33928 14.1273C4.32704 14.639 3.98929 14.9976 3.54304 14.9993C3.09646 15.0009 2.7435 14.641 2.73721 14.1359C2.72861 13.3433 2.73456 11.6777 2.73291 10.8848H4.34159C4.34159 11.1831 4.34391 12.3535 4.34391 12.6519H4.34159C4.34192 13.1441 4.35086 13.6357 4.33928 14.1273Z"
      fill={color}
    />
  </svg>
)

Pediatric.propTypes = {
  color: string
}

export default Pediatric
