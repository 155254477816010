/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, Fragment } from 'react'
import { func, arrayOf, shape, object, string, number, oneOfType } from 'prop-types'
import styled, { css } from 'styled-components'

import { darkBlue, white, lightGrey, grey } from '../colors'

import Icon from '../Icon'

const MIN_OPTION_WIDTH = '100px'

const StyledDropdown = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`

const styleOption = css`
  width: 100%;
  padding: 5px 15px;
  font-size: 14px;
  color: ${darkBlue};
  text-align: left;
`

const StyledOption = styled.div`
  cursor: pointer;
  ${styleOption}
  background: ${white};

  :hover {
    background-color: ${lightGrey};
  }
`

const StyledGroupTitle = styled.div`
  ${styleOption}
  color: ${grey};
  font-size: 12px;
  text-transform: uppercase;
`

const StyledSelectedOption = styled(StyledOption)`
  min-width: ${({ width }) => (width ? `${width}px` : MIN_OPTION_WIDTH)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0 20px 0 5px;
  height: 36px;

  :hover {
    background-color: ${white};
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 5px;
`

const StyledOptions = styled.div`
  min-width: ${({ width }) => (width ? `${width}px` : MIN_OPTION_WIDTH)};
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
  padding: 10px 0;
  background: ${white};
  box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);
  border-radius: 6px;
`

const Dropdown = ({
  options = [],
  value,
  onChange,
  width,
  labelKey = 'key',
  valueKey = 'value',
  className,
  style
}) => {
  const [visible, setVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState(value || options[0])

  useEffect(() => {
    if (value && value[labelKey] !== selectedOption[labelKey]) {
      setSelectedOption(value)
    }
  }, [labelKey, selectedOption, value])

  const handleOpenDropdown = () => setVisible(!visible)

  const handleSelectOption = option => {
    setSelectedOption(option)
    setVisible(false)
    onChange(option)
  }

  const renderOptions = (optionsToRender, style) =>
    optionsToRender.length &&
    optionsToRender.map(option => (
      <StyledOption
        key={`${option[labelKey]}-${option[valueKey]}`}
        onClick={() => handleSelectOption(option)}
        style={style}
      >
        {option[labelKey]}
      </StyledOption>
    ))

  return (
    <StyledDropdown className={className} style={style}>
      <StyledSelectedOption width={width} onClick={handleOpenDropdown}>
        {selectedOption[labelKey]}
        <StyledIcon name="arrow-down" size={10} />
      </StyledSelectedOption>
      {options.length && visible && (
        <StyledOptions width={width}>
          {options.map((option, indexGroup) => {
            const hasGroup = !!option.options

            return hasGroup ? (
              <Fragment key={indexGroup}>
                <StyledGroupTitle>{option[labelKey]}</StyledGroupTitle>
                {renderOptions(option.options, { paddingRight: 25 })}
              </Fragment>
            ) : (
              <StyledOption
                key={`${option[labelKey]}-${option[valueKey]}`}
                onClick={() => handleSelectOption(option)}
                style={style}
              >
                {option[labelKey]}
              </StyledOption>
            )
          })}
        </StyledOptions>
      )}
    </StyledDropdown>
  )
}

const value = shape({
  key: string,
  value: oneOfType([string, number])
})

Dropdown.propTypes = {
  options: arrayOf(value).isRequired,
  value,
  onChange: func.isRequired,
  width: number,
  className: string,
  style: object,
  labelKey: string,
  valueKey: string
}

export default Dropdown
