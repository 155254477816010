import React from 'react'
import { node, bool, string, object } from 'prop-types'
import styled from 'styled-components'

import { darkBlue, black } from '../colors'

const getLabelColor = ({ light, color }) => {
  if (color) return color
  return light ? darkBlue : black
}

const StyledLabel = styled.p`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 29px;
  color: ${getLabelColor};
  opacity: 0.5;
  margin: 0;
`

const Label = ({ light, color, children, className, style }) => (
  <StyledLabel light={light} color={color} style={style} className={className}>
    {children}
  </StyledLabel>
)

Label.propTypes = {
  children: node,
  light: bool,
  color: string,
  className: string,
  style: object
}

export default Label
